/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import fxpro from '../assets/img/Fxpro logo review.svg'
import { TiTick } from "react-icons/ti";
import { LuExternalLink } from "react-icons/lu";

const Fxpro = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
          },[])
    const minRating = 0.1;
    const maxRating = 5.0;
    return (
        <>
            <Header />
            <div className="container py-4 px-2">
                <div className='col-lg-10 col-md-9 offset-md-1'>
                    <h3 className='pt-4 pb-4  broker-name'>FxPro Review</h3>
                    <div className="row  pt-5 pb-5 text-center">
                        <div

                            className="col-lg-6 col-md-8 col-sm-12 offset-md-3  pricing-block style-two animated fadeInUp "
                            data-wow-delay="200ms"
                            data-wow-duration="1200ms"
                        >
                            <div
                                className="inner-box1 py-2 px-4"
                                
                            >

                                <h4 style={{ marginTop: "5px" }}>
                                    <a href='' target="_blank">
                                        <img
                                            src={fxpro}
                                            width={"200"}
                                            height={"100"}
                                            alt=""
                                            srcset=""
                                            className="mb-2 rounded-2"
                                        />
                                    </a>
                                </h4>
                                <div className="d-flex py-1  justify-content-center  align-items-center  flex-reverse ">

                                    <div className=" d-flex justify-content-center">
                                        <div class="ratings">
                                            <div class="empty-stars"></div>
                                            <div
                                                className="full-stars"
                                                style={{
                                                    width: `${((5 - minRating) /
                                                        (maxRating - minRating)) *
                                                        100
                                                        }%`,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div
                                        className={`py-2`}>
                                        <div className="grid grid-cols-12 text-sm">
                                            <div className="text-left lh-lg">
                                                <p>
                                                    <TiTick />
                                                    <strong> Min. Deposit: </strong> $100
                                                </p>

                                                <p className='fs-6'> <TiTick /><strong>Max. Leverage: </strong>200:1</p>
                                                <p className='fs-6'> <TiTick /><strong>Headquarter: </strong> London, UK</p>
                                                <p className='fs-6'> <TiTick /><strong>Products (CFD):</strong>   Forex, Shares, Futures, Metals, Indices, Energy, Cryptocurrencies
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-1  my-4 btn-style-two1">
                                    <a href='' target="_blank" className=" w-100 text-align-center text-light">

                                        <span className="">VISIT FxPro {' '}</span>
                                        <LuExternalLink color='#fff' size='25' style={{ paddingBottom: '5px' }} />
                                    </a>
                                </div>

                                <div className="hint">

                                    <p className="tradepara1 py-2">
                                        82% of retail CFD accounts lose money
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="row lg-pt-5 lg-px-5">

                        <h2 className='pt-4 pb-4 font-bold'>Overview</h2>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>FxPro is in the contracts for difference (CFD) and spread betting market in the U.K. Its headquarters is in London, and it was founded in 2006. Since then, the company has executed more than 445 million orders and was voted the U.K.’s most trusted forex brand by Global Brands Magazine in 2017.
                                <br /><br />Today, the company operates in more than 170 countries and has more than 2,188,000 client accounts and €100 million of tier 1 capital. FxPro says it is known throughout the industry as a trustworthy and reliable broker. It is regulated by the FCA, CySEC, FSCA, and SCB.</p>
                        </div>


                        <div className="col-lg-12 py-5">
                            <div className='row'>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h5 className='pb-1 font-bold'>Pros</h5>
                                    <ul className='lh-lg' style={{ listStyle: "none" }}>
                                        <li>✅ Real-time news
                                        </li>
                                        <li>✅ Above-average product offering



                                        </li>
                                        <li>✅ Transparent pricing
                                        </li>
                                    </ul>


                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h5 className='pb-1 font-bold'>Cons</h5>
                                    <ul className='lh-lg' style={{ listStyle: "none" }}>
                                        <li>❌Demo account requires registration



                                        </li>
                                        <li>❌Weak educational platform
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h2 className='pt-4 pb-4 font-bold'>Trust </h2>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>
                                FxPro scores quite well when it comes to reputation and trustworthiness. Most importantly, it is regulated in the United Kingdom, which is reputedly safer than some regulators in the EU. FxPro also advertises that it has one of the highest counterparty credit ratings in the industry, scoring 96 on a 100-point scale, where a high score indicates a lower risk of default or bankruptcy.


                                <br />
                                <br />
                                FxPro participates in the Financial Services Compensation Scheme (FSCS), which allows clients to claim compensation in the event FxPro were to become insolvent. It offers clients negative balance protection under new EU guidelines that prevent clients from losing more money than they have deposited.


                                <br />
                                <br />

                                One negative for FxPro is the absence of guaranteed stop‑loss orders. Some brokers offer this feature for a fee, but FxPro does not offer this at all.




                            </p>
                        </div>


                        <h2 className='pt-4 pb-4 font-bold'>Desktop Experience
                        </h2>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>FxPro offers clients a wide range of desktop trading platforms, including MetaTrader 4, MetaTrader 5, cTrader, and FxPro Trading Platform. The desktop browser experience is in line with the industry average and allows traders to easily execute trades with clear and transparent pricing. Charting is also available with various indicators and studies. Traders can also create customizable watchlists and leave conditional orders, but hedging is not possible on MetaTrader 5 and will simply cancel offsetting orders.
                                <br />
                                <br />
                                Simulated demo‑account trading is available but only after providing personal identification documents. There is also a running news feed with the latest market headlines and a link to Trading Central WebTV.







                            </p>
                            <h2 className='pt-4 pb-4 font-bold'>Mobile Experience
                            </h2>
                            FxPro has a good mobile offering on both iOS and Android. Security features are standard, with the option to replace the login with Apple’s Touch ID. Watchlists are easily customizable and it is also possible to have multiple lists.

                            <br />
                            <br />
                            Mobile price alerts are available. Traders simply need to select the instrument, decide which price to alert (bid or ask), and then choose to alert when the price is higher or lower than a selected level. The trader can even create a customized pop‑up message to deliver as well.



                            <br /><br />
                            Unlike other brokers, FxPro offers streaming mobile news with major news headlines that can impact markets. This allows traders to follow market developments on the go in real time. Conditional orders are easy and straightforward to set up with various types on offer. Traders can choose between market, limit, stop, and stop‑limit orders. Traders can also specify when the order will expire, providing another degree of control.



                            <br />
                            <br />
                            Finally, FxPro has an acceptable mobile charting offering. Traders can choose multiple time frames to monitor and can easily add multiple trading indicators, such as moving averages. After setting up a chart, the mobile app allows traders to easily trade from the chart, with easy trade sizing and buy and sell directional orders.



                        </div>


                        <h2 className='pt-4 pb-4 font-bold'>Research Tools
                        </h2>
                        <div className="col-lg-12">
                            <p>FxPro’s research and insights tools are above average for the industry. Like many brokers, FxPro offers a macroeconomic calendar to track important releases that can impact markets. They also offer some basic daily fundamental research and work with Trading Central to offer technical analysis. Two areas where FxPro has an advantage over other brokers are its multilingual webinars available on the company’s YouTube channel and the quality of its real-time news offering. These tools seem to be available even for traders who do not have an account with FxPro.



                            </p>


                        </div>

                        <h2 className='pt-4 pb-4 font-bold'>Education</h2>

                        <p>FxPro has an educational page providing beginners with content to learn about trading, CFDs, finance, and other topics relevant to the company’s offerings. There are both videos and written lessons. FxPro also has an advanced education section for those who already know the basics. For more content, their YouTube channel also has a short educational playlist. FxPro also provides a glossary of CFD terms, which is not something every broker provides.</p>
                        <h2 className='pt-4 pb-4 font-bold'>Investment Products   </h2>
                        <div className="col-lg-12">
                            <p className='lh-base fs-6'>FxPro scores above average in terms of available investment products. The company offers 70‑plus forex pairs as well as a variety of different instruments across five additional asset classes: stock indices, stock shares, energy, metals, and cryptocurrency CFDs. In total, this broker claims to have more than 2,100 instruments available to trade.

                            </p>
                            <h2 className='pt-4 pb-4 font-bold'>Investment Products   </h2>
                            <p>FxPro is regulated in several jurisdictions including:

                            </p>
                            <ul>
                                <li>FxPro UK Limited is authorised and regulated by the Financial Conduct Authority (FCA) registration no. 509956.
                                </li>
                                <li>FxPro Financial Services Limited is authorised and regulated by the Cyprus Securities and Exchange Commission (CySEC) licence no. 078/07.
                                </li>
                                <li>FxPro Global Markets Limited is authorised and regulated by the Securities Commission of The Bahamas (SCB) license no. SIA-F184.
                                </li>
                                <li>FxPro Financial Services Limited is authorised by the Financial Sector Conduct Authority (FSCA) authorisation no. 45052.
                                </li>
                            </ul>

                            <h2 className='pt-4 pb-4 font-bold'>Commissions and Fees
                            </h2>
                            <p>
                                Like many brokers, FxPro has no commissions on most trades. One thing it advertises is the lack of an expensive dealing desk, which allows the company to pass on cost savings to clients. FxPro says that due to the high volumes of their clients’ trades, it can internally match a great deal of their order flow. This allows the company to minimize risk and costs without interfering with orders in any way. The only situations that do have a commission are the FxPro MT4 Raw Spread account which charges $3.50 per lot when opening and closing as well as cTrader which charges $35 per $1 million traded upon opening and closing.7 FxPro does not seem to offer any discounts for higher-volume traders on the couple commission types it does charge.
                                <br /><br />
                                Accounts are disabled after three months of inactivity. It’s not clear if a fee is charged for this, but it’s common in the industry.</p>
                        </div>
                        <h2 className='pt-4 pb-4 font-bold'>Customer Support

                        </h2>
                        <p>
                        FxPro prides itself on its “five‑star customer service,” specifically its multilingual, 24‑hour Monday‑through‑Friday (24/5) customer service team. Its live phone support operates in several locations, which include the United Kingdom, Cyprus, Monaco, Bahamas, and the United Arab Emirates. The U.K. number is toll free for residents of that country.
                        <br /><br />
                        Unlike other brokers, FxPro has a physical office in London with a reception desk that is open from 7:30 a.m. to 4:30 p.m.9 Some social media support is available on X (formerly Twitter), but this is mainly news related rather than specifically for customer support.</p>
                        

                        <h2 className='pt-4 pb-4 font-bold'>Final Thoughts


                        </h2>
                        <p>
                        FxPro is best for forex and CFD traders who want a wealth of choices in trading markets. Their range of forex pairs and the ability to trade equity indices, individual company shares, cryptocurrencies, metals, and energy set them above competitors in this space. While most trades are commission-free, some may be irked by commissions charged when using their cTrader automated platform.

</p>



                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Fxpro