/* eslint-disable react/jsx-pascal-case */

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css"


import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Allreview from './pages/Allreview';
import CompareBrokermain from './pages/CompareBrokermain';
import NewCompareBroker from './pages/NewCompareBroker';
import FeeCalculator from './pages/FeeCalculator';
import ReviewDetail from './pages/ReviewDetail';
import Activetrades from './pages/Activtraders-review';
import Xtb_review from './pages/Xtb-review';
import IGreview from './pages/IG-review';
import PapperStone from './pages/Pepperstone-review-2';
import Forex_Com from './pages/Forex-com';
import Fxpro from './pages/Fxpro-review';
import City_index from './pages/City-index-review';
import Cmc_markets from './pages/Cmc-markets-review';
// import MyComponent from './components/Check';
import Whatforex from './pages/WhatisForex';
import Whatcfd from './pages/Whatiscfd';
import Whoforex from './pages/Whotradeforex';
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route  path="/" element={<Home />}></Route>
      <Route  path="/allreviews" element={<Allreview />}></Route>
      <Route  path="/comparebroker" element={<NewCompareBroker />}></Route>
      <Route  path="/comparebroker2" element={<CompareBrokermain />}></Route>
      <Route  path="/feeCalculator" element={<FeeCalculator />}></Route>
      <Route  path="/reviewdetail" element={<ReviewDetail />}></Route>
      <Route  path="/activtraders-review" element={<Activetrades />}></Route>
      <Route  path="/xtb-review" element={<Xtb_review />}></Route>
      <Route  path="/ig-review" element={<IGreview />}></Route>
      <Route  path="/pepperstone-review-2" element={<PapperStone />}></Route>
      <Route  path="/forex-com-review" element={<Forex_Com />}></Route>
      <Route  path="/fxpro-review" element={<Fxpro />}></Route>
      <Route  path="/city-index-review" element={<City_index />}></Route>
      <Route  path="/cmc-markets-review" element={<Cmc_markets />}></Route>
      <Route  path="/whatisforex" element={<Whatforex />}></Route>
      <Route  path="/what-is-cdf" element={<Whatcfd />}></Route>
      <Route  path="/who-trades-forex" element={<Whoforex />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
