/* eslint-disable react-hooks/exhaustive-deps */
  /* eslint-disable jsx-a11y/anchor-is-valid */
  import React, {  useEffect } from "react";
  import { useState, useRef } from "react";
  import Header from "../components/Header";
  import Footer from "../components/Footer";
  import { Swiper, SwiperSlide } from "swiper/react";
  // import admiralmarket from "./../assets/img/brokerlogoclient/Admirals Markets logo.png";
  // Import Swiper styles
  import "swiper/css";
  // import { Navigation } from "swiper";

  import "swiper/css/pagination";
  // import { Pagination } from "swiper/modules";
  import { AiOutlineArrowRight } from "react-icons/ai";
  import securites from "./../assets/img/brokerlogoclient/ACY Securities logo.jpg";
  import avatrade from "./../assets/img/brokerlogoclient/AvaTrade logo.jpg";
  import axi from "./../assets/img/brokerlogoclient/Axi logo.png";
  import blackbull from "./../assets/img/brokerlogoclient/Blackbull logo.png";
  import captial from "./../assets/img/brokerlogoclient/Capital.com logo.png";
  // import cityindex from "./../assets/img/brokerlogoclient/City Index logo.png";
  // import Eightcap from "./../assets/img/brokerlogoclient/EightCap logo.png";
  // import etoro from "./../assets/img/brokerlogoclient/eToro logo.png";
  import forexcom from "./../assets/img/brokerlogoclient/forex.com logo.png";
  import fpmarket from "./../assets/img/brokerlogoclient/FP Markets logo.png";
  import fusionmarket from "./../assets/img/brokerlogoclient/Fusion Markets logo.jpg";
  import fxcmlogo from "./../assets/img/brokerlogoclient/FXCM logo.png";
  // import fxtm from "./../assets/img/brokerlogoclient/FXTM logo.png";
  import globalprime from "./../assets/img/brokerlogoclient/Global Prime Logo.png";
  // import gomarket from "./../assets/img/brokerlogoclient/Go Markets logo.jpeg";
  // import HFmarket from "./../assets/img/brokerlogoclient/HF Markets.png";
  // import Hycmlogo from "./../assets/img/brokerlogoclient/HYCM logo.png";
  import ICmarket from "./../assets/img/brokerlogoclient/IC Markets logo.png";
  // import IG from "./../assets/img/brokerlogoclient/IG Logo.jpg";
  // import marketcom from "./../assets/img/brokerlogoclient/Markets.com logo.jpeg";
  import monetamarket from "./../assets/img/brokerlogoclient/Moneta Markets logo.png";
  // import multibank from "./../assets/img/brokerlogoclient/MultiBank logo.svg";
  // import oandalogo from "./../assets/img/brokerlogoclient/Oanda Logo.jpg";
  import peperstone from "./../assets/img/brokerlogoclient/Pepperstone logo.png";
  // import swisslogo from "./../assets/img/brokerlogoclient/Swissquote logo.png";
  // import tickmil from "./../assets/img/brokerlogoclient/Tickmill logo.png";
  import tmgm from "./../assets/img/brokerlogoclient/TMGM logo.jpeg";
  // import tradenation from "./../assets/img/brokerlogoclient/Trade Nation logo.png";
  // import vintage from "./../assets/img/brokerlogoclient/Vantage logo.png";
  // import xtm from "./../assets/img/brokerlogoclient/XM logo.jpg";
  import activetrade from "./../assets/img/main-icon.jpg";
  import Select from "react-select";
  import makeAnimated from "react-select/animated";
  import { SlClose } from "react-icons/sl";
  import ProgressBar from "./ProgressBar"; // Import the ProgressBar component

  // import plus500 from "./../assets/img/main-icon1.jpg";
  // import XTB from "./../assets/img/Xtb-logo.png";
  // import CMCmarketlogo from "./../assets/img/brokerlogoclient/CMC Markets Logo.png";
  import { LiaGreaterThanSolid, LiaLessThanSolid } from "react-icons/lia";
  import { BsCheck2Circle } from "react-icons/bs";
  const CompareBrokermain = () => {
    useEffect(()=>{
      window.scrollTo(0,0)
        },[])
    const [isfixed, setIsfixed] = useState(false);
    const [selectedBroker, setSelectedBroker] = useState([]);
    const swiperRef = useRef(null);

    const BrokerDatas = [
      {
        BrokerName: "IC Markets",
        score: "4.6/5",
        Avaiable: "in pakistan",
        popularity: "11k",
        Updated: "OCT 2023",
        img: ICmarket,

        tradingfees: [
          {
            OverallScore: "4.5/5",
            FeeScore: "4.6/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "4.3/5",
            WebPlatformscore: "4.3/5",
            metatraderscore: "3.8/5",
            custumservicescore: "4.3/5",
            Accountoppeningscore: "3.2/5",
          },
        ],
      },
      {
        BrokerName: "Pepperstone",
        score: "4.6/5",
        Avaiable: "in ind",
        Updated: "OCT 2023",
        img: peperstone,
        tradingfees: [
          {
            OverallScore: "4.2/5",
            FeeScore: "4.6/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "No",
            Otherelectronicwallets: "No",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "4.3/5",
            WebPlatformscore: "4.3/5",
            metatraderscore: "3.8/5",
            custumservicescore: "4.3/5",
            Accountoppeningscore: "3.2/5",
          },
        ],
      },
      {
        BrokerName: "Fusion Markets",
        score: "4.6/5",
        Avaiable: "in sa",
        Updated: "OCT 2023",
        img: fusionmarket,
        tradingfees: [
          {
            OverallScore: "4.1/5",
            FeeScore: "4.6/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "5.0/5",
            Accountoppeningscore: "5.0/5",
          },
        ],
      },
      {
        BrokerName: "securites",
        score: "4.6/5",
        Avaiable: "in was",
        Updated: "OCT 2023",
        img: securites,
        tradingfees: [
          {
            OverallScore: "4.1/5",
            FeeScore: "4.6/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "No",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "5.0/5",
            WebPlatformscore: "5.0/5",
            metatraderscore: "yes",
            custumservicescore: "4.6/5",
            Accountoppeningscore: "4.2/5",
          },
        ],
      },
      {
        BrokerName: "monetamarket",
        score: "4.6/5",
        Avaiable: "in pakistan",
        Updated: "OCT 2023",
        img: monetamarket,
        tradingfees: [
          {
            OverallScore: "4.4/5",
            FeeScore: "4.6/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "4.5/5",
            Accountoppeningscore: "5.0/5",
          },
        ],
      },
      {
        BrokerName: "FXCM",
        score: "4.6/5",
        Avaiable: "in pakistan",
        Updated: "OCT 2023",
        img: fxcmlogo,
        tradingfees: [
          {
            OverallScore: "4.3/5",
            FeeScore: "4.6/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "No",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "5.5/5",
            Accountoppeningscore: "4.2/5",
          },
        ],
      },

      {
        BrokerName: "Axi",
        score: "4.6/5",
        Avaiable: "in pakistan",
        Updated: "OCT 2023",
        img: axi,
        tradingfees: [
          {
            OverallScore: "4.2/5",
            FeeScore: "4.6/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "4.5/5",
            Accountoppeningscore: "5.0/5",
          },
        ],
      },
      {
        BrokerName: "fpmarket",
        score: "4.6/5",
        Avaiable: "in pakistan",
        Updated: "OCT 2023",
        img: fpmarket,
        tradingfees: [
          {
            OverallScore: "4.1/5",
            FeeScore: "4.6/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "4.5/5",
            Accountoppeningscore: "5.0/5",
          },
        ],
      },
      {
        BrokerName: "BlackBull Markets",
        score: "4.6/5",
        Avaiable: "in pakistan",
        Updated: "OCT 2023",
        img: blackbull,
        tradingfees: [
          {
            OverallScore: "4.9/5",
            FeeScore: "4.6/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "4.5/5",
            Accountoppeningscore: "5.0/5",
          },
        ],
      },
      {
        BrokerName: "ActivTrades",
        score: "4.6/5",
        Avaiable: "in pakistan",
        Updated: "OCT 2023",
        img: activetrade,
        tradingfees: [
          {
            OverallScore: "4.8/5",
            FeeScore: "4.6/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "4.5/5",
            Accountoppeningscore: "5.0/5",
          },
        ],
      },
      {
        BrokerName: "Global Prime",
        score: "4.6/5",
        Avaiable: "in pakistan",
        Updated: "OCT 2023",
        img: globalprime,
        tradingfees: [
          {
            OverallScore: "4.7/5",
            FeeScore: "4.9/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "4.5/5",
            Accountoppeningscore: "5.0/5",
          },
        ],
      },
      {
        BrokerName: "captial",
        score: "4.6/5",
        Avaiable: "in pakistan",
        Updated: "OCT 2023",
        img: captial,
        tradingfees: [
          {
            OverallScore: "4.0/5",
            FeeScore: "4.8/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "4.5/5",
            Accountoppeningscore: "5.0/5",
          },
        ],
      },
      {
        BrokerName: "FxPro",
        score: "4.6/5",
        Avaiable: "in pakistan",
        Updated: "OCT 2023",
        img: fxcmlogo,
        tradingfees: [
          {
            OverallScore: "4.4/5",
            FeeScore: "4.2/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "4.5/5",
            Accountoppeningscore: "5.0/5",
          },
        ],
      },
      {
        BrokerName: "AvaTrade",
        score: "4.6/5",
        Avaiable: "in pakistan",
        Updated: "OCT 2023",
        img: avatrade,
        tradingfees: [
          {
            OverallScore: "4.2/5",
            FeeScore: "4.2/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "4.5/5",
            Accountoppeningscore: "5.0/5",
          },
        ],
      },
      {
        BrokerName: "FXTRADING.com",
        score: "4.6/5",
        Avaiable: "in pakistan",
        Updated: "OCT 2023",
        img: forexcom,
        tradingfees: [
          {
            OverallScore: "4.1/5",
            FeeScore: "4.6/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "4.5/5",
            Accountoppeningscore: "5.0/5",
          },
        ],
      },
      {
        BrokerName: "TMGM",
        score: "4.6/5",
        Avaiable: "in pakistan",
        Updated: "OCT 2023",
        img: tmgm,
        tradingfees: [
          {
            OverallScore: "3.9/5",
            FeeScore: "4.6/5",
            USstock: "$15",
            EURUSDspread: "$0.6",
            USstockindexoptions: "$10",
            SP500indexCFDfee: "$10",
          },
        ],
        Nontradingfees: [
          {
            WithdrawlFee: "$0",
            DepositFee: "$0",
            InactiveFee: "yes",
          },
        ],
        Safety: [
          {
            Toptierregulators:
              "FCA in the UK, BaFin in Germany, and ASIC in Australia",
            Funding_Methods: "Credit/Debit Card Bank Transfer,Neteller Skrill,Astropay",
            Investorprotection: "yes",
          },
        ],
        DepositandWithdrawal: [
          {
            Minimumdeposit: "$0",
            Creditdebitcard: "yes",
            PayPalfordeposit: "yes",
            Wisefordeposit: "yes",
            Revolutfordeposit: "yes",
            Otherelectronicwallets: "yes",
          },
        ],
        PlatformandExperience: [
          {
            mobileplatformscore: "3.8/5",
            WebPlatformscore: "2.8/5",
            metatraderscore: "yes",
            custumservicescore: "4.5/5",
            Accountoppeningscore: "5.0/5",
          },
        ],
      },
    ];
    
    const [activeSlidesData, setActiveSlidesData] = useState([
      BrokerDatas[0],
      BrokerDatas[1],
      BrokerDatas[2],
      // BrokerDatas[3],
      // BrokerDatas[4],
    ]);
  


    console.log(activeSlidesData, "activeSlidesData");
 
    
    const handleChange = (selectedOptions) => {
      setSelectedBroker(selectedOptions);
    };
    console.log(selectedBroker, "selectedBroker");
    // const selectedValues = selectedBroker.map((selected) =>
    //   selected.value.toLowerCase()
    // );

    // const filteredData = BrokerDatas.filter((broker) => {
    //   // Check if the BrokerName exists in the selected values array
    //   return selectedValues.includes(broker.BrokerName.toLowerCase());
    // });
    const [activeSlidesData2, setActiveSlidesData2] = useState(null);
    const [filteredData, setfilteredData] = useState([]);

    useEffect(() => {
      const selectedValues = selectedBroker.map((selected) => selected.value.toLowerCase());

      const filteredData = BrokerDatas.filter((broker) => selectedValues.includes(broker.BrokerName.toLowerCase()));
      setfilteredData(filteredData)
      if (filteredData.length > 0) {
        setActiveSlidesData([]);
        setActiveSlidesData2(filteredData);
      } else {
        setActiveSlidesData2(null);
        setActiveSlidesData(BrokerDatas.slice(0, 3));
      }
    }, [selectedBroker]);
    
    
  const handleSlideChange = (swiper) => {
    const activeIndexes = [
      swiper.realIndex,
      swiper.realIndex + 1,
      swiper.realIndex + 2, 
      swiper.realIndex + 3,
      // swiper.realIndex + 4,
    ];
    if (filteredData.length===0) {
      setActiveSlidesData(BrokerDatas.slice(activeIndexes[0], activeIndexes[0] + 3));
    } else {
      setActiveSlidesData(null)
      setActiveSlidesData2(filteredData.slice(activeIndexes[0], activeIndexes[0] + filteredData.length));
    }
  
  
  };


    const animatedComponents = makeAnimated();
    const options = BrokerDatas?.map((e) => {
      return { label: `${e?.BrokerName}`, value: e?.BrokerName };
    });
 
    useEffect(() => {
      const handleScroll = () => {
        const scrollAmount1 = 280;
        const scrollAmount2 = 950;
        const scrollY = window.pageYOffset;

        if (scrollY >= scrollAmount1 && scrollY < scrollAmount2) {
          setIsfixed(true);
        } else {
          setIsfixed(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const goNext = () => {
      if (swiperRef.current) {
        swiperRef.current.swiper.slideNext();
      }
    };

    const goPrev = () => {
      if (swiperRef.current) {
        swiperRef.current.swiper.slidePrev();
      }
    };


    console.log(activeSlidesData2, "activeSlidesData2");
    // const [numActiveSlides, setNumActiveSlides] = useState(2);


  const maptodata= activeSlidesData !==null && activeSlidesData.length > 0 ? activeSlidesData :  activeSlidesData2
  console.log(maptodata,"mapdata")
    const overallScore = maptodata
      ?.map((e, i) => e?.tradingfees.map((e) => e?.OverallScore))
      .flat()
      .map(parseFloat);
    console.log(overallScore, "overallscore");
    const minScore = 1.1; // Minimum score
    const maxScore = 5.0; // Maximum score
    const backgroundColors = [];

    for (const score of overallScore) {
      // Ensure score is within the range
      const clampedScore = Math.min(Math.max(score, minScore), maxScore);

      // Calculate the width percentage for the current score
      const widthPercentage =
        ((clampedScore - minScore) / (maxScore - minScore)) * 100;

      // Calculate the background color based on the score
      // const red = Math.floor(255 * (1 - widthPercentage / 100));
      // const green = Math.floor(128 + widthPercentage / 2);
      // const blue = Math.floor(255 * (widthPercentage / 100));
      const backgroundColor = `#08bb68
      `;

      backgroundColors.push({ widthPercentage, backgroundColor });
    }
    console.log(backgroundColors, "backgroundColors");

    //fee score
    const Feescore = maptodata
      ?.map((e, i) => e?.tradingfees.map((e) => e?.FeeScore))
      .flat()
      .map(parseFloat);
    console.log(Feescore, "Feescore");

    const backgroundColors2 = [];

    for (const score of Feescore) {
      // Ensure score is within the range
      const clampedScore = Math.min(Math.max(score, minScore), maxScore);

      // Calculate the width percentage for the current score
      const widthPercentage =
        ((clampedScore - minScore) / (maxScore - minScore)) * 100;

      // Calculate the background color based on the score
      // const red = Math.floor(255 * (1 - widthPercentage / 100));
      // const green = Math.floor(128 + widthPercentage / 2);
      // const blue = Math.floor(255 * (widthPercentage / 100));
      const backgroundColor = `#08bb68
      `;

      backgroundColors2.push({ widthPercentage, backgroundColor });
    }

    //fee score
    //mobileplatform
    const mobileplatform = maptodata
      ?.map((e, i) => e?.PlatformandExperience.map((e) => e?.mobileplatformscore))
      .flat()
      .map(parseFloat);
    console.log(mobileplatform, "Feescore");

    const backgroundColors3 = [];

    for (const score of mobileplatform) {
      // Ensure score is within the range
      const clampedScore = Math.min(Math.max(score, minScore), maxScore);

      // Calculate the width percentage for the current score
      const widthPercentage =
        ((clampedScore - minScore) / (maxScore - minScore)) * 100;

      // Calculate the background color based on the score
      // const red = Math.floor(255 * (1 - widthPercentage / 100));
      // const green = Math.floor(128 + widthPercentage / 2);
      // const blue = Math.floor(255 * (widthPercentage / 100));
      const backgroundColor = `#08bb68
      `;

      backgroundColors3.push({ widthPercentage, backgroundColor });
    }

    console.log(backgroundColors3, "backgroundColors3");

    //mobileplatform

    const overallScore2 = maptodata
      ?.map((e, i) => e?.tradingfees.map((e) => e?.OverallScore))
      .flat()
      .map(parseFloat);
    console.log(overallScore2, "overallscore2");
    // Maximum score
    const backgroundColors4 = [];

    for (const score of overallScore2) {
      // Ensure score is within the range
      const clampedScore = Math.min(Math.max(score, minScore), maxScore);

      // Calculate the width percentage for the current score
      const widthPercentage =
        ((clampedScore - minScore) / (maxScore - minScore)) * 100;

      // Calculate the background color based on the score
      // const red = Math.floor(255 * (1 - widthPercentage / 100));
      // const green = Math.floor(128 + widthPercentage / 2);
      // const blue = Math.floor(255 * (widthPercentage / 100));
      const backgroundColor = `#08bb68
  `;

      backgroundColors4.push({ widthPercentage, backgroundColor });
    }
    console.log(backgroundColors4, "backgroundColors4");
    const feescore2 = maptodata
      ?.map((e, i) => e?.tradingfees.map((e) => e?.FeeScore))
      .flat()
      .map(parseFloat);
    console.log(overallScore2, "5");
    // Maximum score
    const backgroundColors5 = [];

    for (const score of feescore2) {
      // Ensure score is within the range
      const clampedScore = Math.min(Math.max(score, minScore), maxScore);

      // Calculate the width percentage for the current score
      const widthPercentage =
        ((clampedScore - minScore) / (maxScore - minScore)) * 100;

      // Calculate the background color based on the score
      // const red = Math.floor(255 * (1 - widthPercentage / 100));
      // const green = Math.floor(128 + widthPercentage / 2);
      // const blue = Math.floor(255 * (widthPercentage / 100));
      const backgroundColor = `#08bb68
  `;

      backgroundColors5.push({ widthPercentage, backgroundColor });
    }
    console.log(backgroundColors5, "backgroundColors5");

    const mobileplatform2 = maptodata
      ?.map((e, i) => e?.PlatformandExperience.map((e) => e?.mobileplatformscore))
      .flat()
      .map(parseFloat);
    console.log(mobileplatform, "Feescore");

    const backgroundColors6 = [];

    for (const score of mobileplatform2) {
      // Ensure score is within the range
      const clampedScore = Math.min(Math.max(score, minScore), maxScore);

      // Calculate the width percentage for the current score
      const widthPercentage =
        ((clampedScore - minScore) / (maxScore - minScore)) * 100;

      // Calculate the background color based on the score
      // const red = Math.floor(255 * (1 - widthPercentage / 100));
      // const green = Math.floor(128 + widthPercentage / 2);
      // const blue = Math.floor(255 * (widthPercentage / 100));
      const backgroundColor = `#08bb68
      `;

      backgroundColors6.push({ widthPercentage, backgroundColor });
    }
    const selectStyles = {
      control: (provided) => ({
        ...provided,
        width: 400, // Set the desired width (in pixels)
      }),
      menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Set a high z-index value
    }),
    };
    return (
      <>
        <Header />
        <section className="comparebroker container-fluid py-5">
          <h4 className="text-center py-4" style={{ background: "aliceblue" }}>
            COMPARE ONLINE BROKER
          </h4>
        
                  <div className="d-flex w-100 justify-content-center pb-4">
                    <div className="select-group">
                    {/* <div className={isfixed ? "fixed-div" : "left-2"}> */}
  <div>
                  <h4 className="text-center">Select Broker</h4>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onChange={handleChange}
                    isMulti
                    styles={selectStyles} 
                    options={options}
                  />
                  </div>
                </div>
                </div>
          <div className="swiper-broker w-100">
            <div className="border-0 border-lg-bottom border-slate-200 py-2 flex mx-auto max-w-screen-2xl px-3">
              <div className="flex-1" />
              <div className="flex-1 d-lg-none" />
              <div className="d-flex align-items-center w-100 py-1">
                <div className="d-flex justify-content-between mx-auto items-center w-100 gap-4 d-lg-gap-4 d-lg-justify-content-center d-lg-w-auto align-items-center">
                  <button
                    type="button"
                    className="h-11 w-11 d-lg-h-7 d-lg-w-7 d-flex justify-content-center align-items-center p-1"
                    style={{
                      background: "transparent",
                      outline: "none",
                      border: "none",
                      marginLeft: "80px",
                    }}
                    onClick={goPrev}
                  >
                    <div className="h-7 w-7 rounded-circle border border-slate-300 d-flex justify-content-center align-items-center p-1">
                      <LiaLessThanSolid />
                    </div>
                  </button>
                  <div className="text-sm font-normal text-slate-600 d-lg-none">
                    16 - 20 of 32 items
                  </div>
                  <div className="d-lg-block text-sm font-normal text-slate-600 text-center">
                    <span className="font-weight-bold">16</span>-
                    <span className="font-weight-bold">20</span> brokers (out of{" "}
                    <span className="font-weight-bold">32</span> available in{" "}
                    <span className="text-capitalize">Pakistan</span>)
                  </div>
                  <button
                    type="button"
                    className="p-2"
                    style={{
                      background: "transparent",
                      outline: "none",
                      border: "none",
                    }}
                    onClick={goNext}
                  >
                    <div className="h-7 w-7 rounded-circle border border-slate-300 d-flex justify-content-center align-items-center p-1">
                      <LiaGreaterThanSolid />
                    </div>
                  </button>
                </div>
                <div className="d-flex justify-content-center position-absolute bottom-1.5 d-lg-static d-lg-bottom-0">
                  <div
                    className="d-flex gap-2 align-items-center justify-content-center overflow-hidden"
                    style={{ maxWidth: 60 }}
                  >
                    <div className="rounded-circle bg-secondary shrink-0 opacity-30 w-1.5 h-1.5" />
                    <div className="rounded-circle bg-secondary shrink-0 transition-height duration-200 opacity-30 w-1.5 h-1.5" />
                    <div className="rounded-circle bg-secondary shrink-0 transition-height duration-200 opacity-30 w-2 h-2" />
                    <div className="rounded-circle bg-secondary shrink-0 transition-height duration-200 opacity-100 w-2 h-2" />
                    <div className="rounded-circle bg-secondary shrink-0 transition-height duration-200 opacity-30 w-2 h-2" />
                    <div className="rounded-circle bg-secondary shrink-0 transition-height duration-200 opacity-30 w-1.5 h-1.5" />
                    <div className="rounded-circle bg-secondary shrink-0 opacity-30 w-1.5 h-1.5" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 d-flex justify-content-end align-items-center gap-4" />

            <div className="row py-3">
              {/* <div className={"col-md-2"}>
                <div className={isfixed ? "fixed-div" : "left-2"}>
                  <h4>Select Broker</h4>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onChange={handleChange}
                    isMulti
                    options={options}
                  />
                  <div className="d-flex flex-column mt-2">
                    <div className="select-group">
                      <h5>You live in</h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="color"
                        CountryOptions={countryoption}
                      />
                    </div>
                  </div>
                  <div className="select-group">
                    <h5>want to invest in</h5>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="color"
                      CountryOptions={countryoption}
                    />
                  </div>
                  <div className="select-group">
                    <h5>Ammount to invest</h5>
                    <Select
                      className="basic-single"  
                      classNamePrefix="select"
                      name="color"
                      CountryOptions={countryoption}
                    />
                  </div>
          
                  <div className="select-group">
                    <h5>prefeared base currency</h5>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="color"
                      CountryOptions={countryoption}
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-md-12" >
                <Swiper
                  ref={swiperRef}
                  slidesPerView={3}
                  spaceBetween={10}
                  onSlideChange={(swiper) => handleSlideChange(swiper)}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                  navigation={true}
                  allowTouchMove={false}
                  className="mySwiper " 
                >
                  {
                filteredData.length===0 ? BrokerDatas.map((e, i) => (
                        <SwiperSlide key={i}>
                          <div className="d-flex flex-column align-items-center">
                            <img
                              src={e?.img}
                              width={"50"}
                              height={"50"}
                              className="rounded-2"
                              alt=""
                              srcset=""
                              style={{boxShadow:"5px 5px 10px rgba(0,0,0,.23)"}}
                            />
                            <h6 className="text-center py-3">{e?.BrokerName}</h6>

                            <div></div>
                            <div className="link-btn">
                              <a href="#" className="theme-btn btn-style-two">
                                <span
                                  className="btn-title"
                                  style={{
                                    padding: "3px 7px",
                                  }}
                                >
                                  VISIT BROKER
                                  <AiOutlineArrowRight />
                                </span>
                              </a>
                            </div>
                            <div className="hint">
                              <p className="tradepara py-1 mt-2 mb-0">
                                82% of retail CFD accounts lose money
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      )):null
                    }
                  {filteredData.map((e, i) =>   (
                    <SwiperSlide key={i}>
                      <div className="d-flex flex-column align-items-center">
                        <img
                          src={e?.img}
                          width={"50"}
                          height={"50"}
                          className="rounded-2"
                          alt=""
                          style={{boxShadow:"5px 5px 10px rgba(0,0,0,.23)"}}
                          srcset=""
                        />
                        <h6 className="text-center py-3">{e?.BrokerName}</h6>

                        <div></div>
                        <div className="link-btn">
                          <a href="#" className="theme-btn btn-style-two">
                            <span
                              className="btn-title"
                              style={{
                                padding: "3px 7px",
                              }}
                            >
                              VISIT BROKER
                              <AiOutlineArrowRight />
                            </span>
                          </a>
                        </div>
                        <div className="hint">
                          <p className="tradepara py-1 mt-2 mb-0">
                            82% of retail CFD accounts lose money
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Swiper
                  spaceBetween={30}
                  slidesPerView={3}
                  navigation
                  allowTouchMove={false}
                  pagination={{ clickable: true }}
                >
                {maptodata.map((data, index) => (
                        <SwiperSlide key={index}>
                          <div className="slider-content">
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <div>
                                <h6>Overall Score</h6>
                              </div>

                              <div className="d-flex mainbar justify-content-center gap-2">
                                <ProgressBar
                                  widthPercentage={
                                    backgroundColors[index].widthPercentage
                                  }
                                  backgroundColor={
                                    backgroundColors[index].backgroundColor
                                  }
                                />

                                <p>
                                  {data?.tradingfees.map((e) => e?.OverallScore)}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center ">
                              <div>
                                <h6>Fee Score</h6>
                              </div>

                              <div className="d-flex mainbar justify-content-center gap-2">
                                <ProgressBar
                                  widthPercentage={
                                    backgroundColors2[index].widthPercentage
                                  }
                                  backgroundColor={
                                    backgroundColors2[index].backgroundColor
                                  }
                                />

                                <p>{data?.tradingfees.map((e) => e?.FeeScore)}</p>
                              </div>
                            </div>

                            {/*    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <h6>EURUSDspread</h6>

                        <p className="text-center">
                    

                          {data?.tradingfees.map((e) => e?.EURUSDspread)}
                        </p>
                        </div>
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                        <h6>USstockindexoptions</h6>
                        <p className="text-center">
                          {data?.tradingfees.map((e) => e?.USstockindexoptions)}
                        </p>
                        </div> */}
                          </div>
                        </SwiperSlide>
                      ))
                }
                
                </Swiper>
                <Swiper
                  spaceBetween={30}
                  slidesPerView={3}
                  navigation
                  allowTouchMove={false}
                  pagination={{ clickable: true }}
                >
                  { maptodata.map((data, index) => (
                        <SwiperSlide key={index}>
                          <div className="slider-content">
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <div>
                                <h6 className="text-center">
                                  Mobile Platform Score
                                </h6>
                              </div>

                              <div className="d-flex mainbar justify-content-center gap-2">
                                <ProgressBar
                                  widthPercentage={
                                    backgroundColors3[index].widthPercentage
                                  }
                                  backgroundColor={
                                    backgroundColors3[index].backgroundColor
                                  }
                                />
                                <p>
                                  {data?.PlatformandExperience.map(
                                    (e) => e?.mobileplatformscore
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="slider-content">
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                          <div>
                          <h6 className="text-center">Web Platform Score</h6>
                          </div>
                      
                          <div className='d-flex mainbar justify-content-center'>         
  //                         <div class="progress-bar">
  //   <div class="progress-fill" style={{width: `${widthPercentage}%`,
  //     background:`${backgroundColor}`}}></div>
  // </div>
  <p>
                          {data?.PlatformandExperience.map((e) => e?.WebPlatformscore)}
                        </p>
  </div>
                      

                        </div>
                        </div>
                        <div className="slider-content">
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                          <div>
                          <h6 className="text-center">MetaTrade Platform Score</h6>
                          </div>
                      
                          <div className='d-flex mainbar justify-content-center'>         
  //                         <div class="progress-bar">
  //   <div class="progress-fill" style={{width: `${widthPercentage}%`,
  //     background:`${backgroundColor}`}}></div>
  // </div>
  <p>
                          {data?.PlatformandExperience.map((e) => e?.mobileplatformscore)}
                        </p>
  </div>
                      

                        </div>
                        </div>
                        <div className="slider-content">
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                          <div>
                          <h6 className="text-center">custumser service score </h6>
                          </div>
                      
                          <div className='d-flex mainbar justify-content-center'>         
  //                         <div class="progress-bar">
  //   <div class="progress-fill" style={{width: `${widthPercentage}%`,
  //     background:`${backgroundColor}`}}></div>
  // </div>
  <p>
                          {data?.PlatformandExperience.map((e) => e?.custumservicescore)}
                        </p>
  </div>
                      

                        </div>
                        </div>
                        <div className="slider-content">
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                          <div>
                          <h6 className="text-center">Account Oppening score </h6>
                          </div>
                      
                          <div className='d-flex mainbar justify-content-center'>         
  //                         <div class="progress-bar">
  //   <div class="progress-fill" style={{width: `${widthPercentage}%`,
  //     background:`${backgroundColor}`}}></div>
  // </div>
  <p>
                          {data?.PlatformandExperience.map((e) => e?.Accountoppeningscore)}
                        </p>
  </div>
                      

                        </div>
                        
                      
                    
                      </div> */}
                        </SwiperSlide>
                      ))
                }
              
                </Swiper>
                <Swiper
                  spaceBetween={30}
                  slidesPerView={3}
                  allowTouchMove={false}
                  navigation
                  pagination={{ clickable: true }}
                >
                  {maptodata.map((data, index) => (
                        <SwiperSlide key={index}>
                          <div className="slider-content">
                            <h6 className="text-center">Deposit Fee</h6>

                            <p className="text-center">
                              {data?.Nontradingfees.map((e) => e?.DepositFee)}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Withdrawl Fee</h6>
                            <p className="text-center">
                              {data?.Nontradingfees.map((e) => e?.WithdrawlFee)}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Inactivity Fee</h6>
                            <p className="text-center">
                              {data?.Nontradingfees.map((e) =>
                                e?.InactiveFee === "yes" ? (
                                  <BsCheck2Circle
                                    style={{ fontSize: "32px", color: "red" }}
                                  />
                                ) : (
                                  <SlClose
                                    style={{ fontSize: "18px", color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                        </SwiperSlide>
                      ))
                }
                
                </Swiper>

                <Swiper
                  spaceBetween={30}
                  slidesPerView={3}
                  allowTouchMove={false}
                  navigation
                  pagination={{ clickable: true }}
                >
                  {maptodata.map((data, index) => (
                        <SwiperSlide key={index}>
                          <div className="slider-content">
                            <h6 className="text-center">Regulations</h6>
                            <p className="text-center">
                              {data?.Safety.map((e) => e?.Toptierregulators)}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Funding Methods</h6>
                            <p className="text-center">
                              {data?.Safety.map((e) => e?.Funding_Methods)}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Investor Protection</h6>
                            <p className="text-center">
                              {data?.Safety.map((e) =>
                                e?.Investorprotection === "yes" ? (
                                  <BsCheck2Circle
                                    style={{ fontSize: "32px", color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    style={{ fontSize: "32px", color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                        </SwiperSlide>
                      ))
                    }
                
                </Swiper>

                <Swiper
                  spaceBetween={30}
                  slidesPerView={3}
                  navigation
                  allowTouchMove={false}
                  pagination={{ clickable: true }}
                >
                  {maptodata.map((data, index) => (
                        <SwiperSlide key={index}>
                          <div className="slider-content">
                            <h6 className="text-center">Minimium Deposit</h6>
                            <p className="text-center">
                              {data?.DepositandWithdrawal.map(
                                (e) => e?.Minimumdeposit
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Maximum Leverage</h6>
                            <p className="text-center">
                              {data?.DepositandWithdrawal.map((e) =>
                                e?.Creditdebitcard === "yes" ? (
                                  <BsCheck2Circle
                                    style={{ fontSize: "32px", color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    style={{ fontSize: "32px", color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Trading Platforms</h6>
                            <p className="text-center">
                              {data?.DepositandWithdrawal.map((e) =>
                                e?.PayPalfordeposit === "yes" ? (
                                  <BsCheck2Circle
                                    style={{ fontSize: "32px", color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    style={{ fontSize: "32px", color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Account Opening Time</h6>
                            <p className="text-center">
                              {data?.DepositandWithdrawal.map((e) =>
                                e?.Wisefordeposit === "yes" ? (
                                  <BsCheck2Circle
                                    style={{ fontSize: "32px", color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    style={{ fontSize: "32px", color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">Headquarters</h6>
                            <p className="text-center">
                              {data?.DepositandWithdrawal.map((e) =>
                                e?.Revolutfordeposit === "yes" ? (
                                  <BsCheck2Circle
                                    style={{ fontSize: "32px", color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    style={{ fontSize: "32px", color: "red" }}
                                  />
                                )
                              )}
                            </p>
                          </div>
                          <div className="slider-content">
                            <h6 className="text-center">
                              Other Electronic Wallet
                            </h6>
                            <p className="text-center">
                              {data?.DepositandWithdrawal.map((e) =>
                                e?.Otherelectronicwallets === "yes" ? (
                                  <BsCheck2Circle
                                    style={{ fontSize: "32px", color: "green" }}
                                  />
                                ) : (
                                  <SlClose
                                    style={{ fontSize: "32px", color: "red" }}
                                  />
                                )
                              )}
                            </p>
                            <div className="hint" style={{ textAlign: "center" }}>
                              <a href="#" className="forhover">
                                {" "}
                                <span
                                  style={{
                                    borderBottom: "1px solid #0a2cd4",
                                    textAlign: "center",
                                  }}
                                >
                                  Read review
                                </span>{" "}
                              </a>
                              <div className="link-btn py-3">
                                <a href="#" className="theme-btn btn-style-two">
                                  <span className="btn-title">VISIT BROKER</span>
                                </a>
                              </div>
                              <p
                                className="tradepara py-1 mt-2 mb-0"
                                style={{ textAlign: "center" }}
                              >
                                82% of retail CFD accounts lose money
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))
                  }
                
                </Swiper>
              </div>

              {/* <div className="d-flex w-100">
    <h6>Fees</h6>
  </div>
  <div className="d-flex w-100">
    <h6>Trading fees</h6>
  </div>
  <div className='row'>
    <div className='col-md-3'>
    <div className="">Overall score</div>
    </div>
    <div className='col-md-9'>
    <Swiper   slidesPerView={5}  >

  <SwiperSlide><h6 className='d-flex justify-content-center'>4.9/5</h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>4.9/5</h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>4.9/5</h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>4.9/5</h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>4.9/5</h6></SwiperSlide>

  </Swiper>
    </div>
  </div>
  <div className='row'>
    <div className='col-md-3'>
    <div className="">Fees score
  </div>
    </div>
    <div className='col-md-9'>
    <Swiper   slidesPerView={5}  >

  <SwiperSlide><h6 className='d-flex justify-content-center'>4.9/5</h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>4.9/5</h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>4.9/5</h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>4.9/5</h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>4.9/5</h6></SwiperSlide>

  </Swiper>
    </div>
  </div>
  <div className='row'>
    <div className='col-md-3'>
    <div className="">US stock
  </div>
    </div>
    <div className='col-md-9'>
    <Swiper   slidesPerView={5}  >

  <SwiperSlide><h6 className='d-flex justify-content-center'>$1.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$0.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$0.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$15.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>-</h6></SwiperSlide>

  </Swiper>
    </div>
  </div>
  <div className='row'>
    <div className='col-md-3'>
    <div className="">US stock
  </div>
    </div>
    <div className='col-md-9'>
    <Swiper   slidesPerView={5}  >

  <SwiperSlide><h6 className='d-flex justify-content-center'>$1.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$0.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$0.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$15.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>-</h6></SwiperSlide>

  </Swiper>
    </div>
  </div>
  <div className='row'>
    <div className='col-md-3'>
    <div className="">US stock
  </div>
    </div>
    <div className='col-md-9'>
    <Swiper   slidesPerView={5}  >

  <SwiperSlide><h6 className='d-flex justify-content-center'>$1.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$0.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$0.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$15.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>-</h6></SwiperSlide>

  </Swiper>
    </div>
  </div>
  <div className='row'>
    <div className='col-md-3'>
    <div className="">US stock
  </div>
    </div>
    <div className='col-md-9'>
    <Swiper   slidesPerView={5}  >

  <SwiperSlide><h6 className='d-flex justify-content-center'>$1.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$0.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$0.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$15.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>-</h6></SwiperSlide>

  </Swiper>
    </div>
  </div>
  <div className='row'>
    <div className='col-md-3'>
    <div className="">US stock
  </div>
    </div>
    <div className='col-md-9'>
    <Swiper   slidesPerView={5}  >

  <SwiperSlide><h6 className='d-flex justify-content-center'>$1.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$0.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$0.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>$15.0
  </h6></SwiperSlide>
  <SwiperSlide><h6 className='d-flex justify-content-center'>-</h6></SwiperSlide>

  </Swiper>
    </div>
  </div> */}
            </div>
          </div>
          <div className={`${isfixed ? 'position-fixed': 'makeitinvisible' }`}>

<Swiper
            // ref={swiperRef2}
                  slidesPerView={3}
                  spaceBetween={10}

                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                  navigation={true}
                  allowTouchMove={false}
                  className="mySwiper " 
                >
                    {
                filteredData.length===0 ? activeSlidesData.map((e, i) => (
                    <SwiperSlide key={i}>
 <div className="slide-wrap">
                            <div className="d-flex flex-column align-items-center">
                            <img
                              src={e?.img}
                              width={"50"}
                              height={"50"}
                              className="rounded-2"
                              alt=""
                              srcset=""
                              style={{boxShadow:"5px 5px 10px rgba(0,0,0,.23)"}}
                            />
                            <h6 className="text-center py-3">{e?.BrokerName}</h6>

                            <div></div>
                            <div className="link-btn">
                              <a href="#" className="theme-btn btn-style-two">
                                <span
                                  className="btn-title"
                                  style={{
                                    padding: "3px 7px",
                                  }}
                                >
                                  VISIT BROKER
                                  <AiOutlineArrowRight />
                                </span>
                              </a>
                            </div>
                            <div className="hint">
                              <p className="tradepara py-1 mt-2 mb-0">
                                82% of retail CFD accounts lose money
                              </p>
                            </div>
                          </div>
                          </div>
                    </SwiperSlide>
                      
                      )):null}
{
  activeSlidesData2?.map((e, i) => (
    <SwiperSlide key={i}>
<div className="slide-wrap">
            <div className="d-flex flex-column align-items-center">
            <img
              src={e?.img}
              width={"50"}
              height={"50"}
              className="rounded-2"
              alt=""
              srcset=""
              style={{boxShadow:"5px 5px 10px rgba(0,0,0,.23)"}}
            />
            <h6 className="text-center py-3">{e?.BrokerName}</h6>

            <div></div>
            <div className="link-btn">
              <a href="#" className="theme-btn btn-style-two">
                <span
                  className="btn-title"
                  style={{
                    padding: "3px 7px",
                  }}
                >
                  VISIT BROKER
                  <AiOutlineArrowRight />
                </span>
              </a>
            </div>
            <div className="hint">
              <p className="tradepara py-1 mt-2 mb-0">
                82% of retail CFD accounts lose money
              </p>
            </div>
          </div>
          </div>
    </SwiperSlide>
      
      ))
}
                    </Swiper>
          
</div>
        </section>
        <Footer />
      </>
    );
  };

  export default CompareBrokermain;
