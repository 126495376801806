import axios from 'axios';
import { baseURL } from './BaseUrl';

const Instance = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default Instance;