import React, { useState } from "react";
import { NavLink } from "react-bootstrap";
import { AiOutlineDown } from "react-icons/ai";
// import { AiOutlineSearch } from "react-icons/ai";
// import { RxAvatar } from "react-icons/rx";
// import flag1 from "./../assets/img/icons/flag_us.svg";
import flag6 from "./../assets/img/icons/flag_uk.svg";
import flag2 from "./../assets/img/icons/flag_australia.svg";
import flag3 from "./../assets/img/icons/flag_singapore.svg";

import flag5 from "./../assets/img/United-Arab-Emirates (1).png";
import flag7 from "./../assets/img/icons/flag_germany.svg";
import flag8 from "./../assets/img/south-africa-flag-icon-32.png";

import review8 from "./../assets/img/brokerlogoclient/Tickmill logo.png";
// import review9 from "./../assets/img/broker_logos/mexem-review.png";
import review10 from "./../assets/img/brokerlogoclient/CMC Markets Logo.png";
import searchicon1 from "./../assets/img/icons/search-icon.svg";
// import searchicon2 from "./../assets/img/icons/compare_icon_box.svg";

import searchicon4 from "./../assets/img/icons/fx_fee_calculator_icon.svg";

import searchicon7 from "./../assets/img/icons/scam_shield_icon.svg";
import logo from "./../assets/img/Get forex-01 (2).png";
// import { IconContext } from 'react-icons/lib';
import { FaBars } from "react-icons/fa";
import ResponsiveNavbar from "./ResponsiveNavbar";
import activetrade from "./../assets/img/main-icon.jpg";
import plus500 from "./../assets/img/brokerlogoclient/Plus500 logo2.png";
import XTB from "./../assets/img/Xtb-logo.png";

import cityindex from "./../assets/img/brokerlogoclient/City Index logo.png";

import forexcom from "./../assets/img/brokerlogoclient/forex.com logo.png";

import fxcmlogo from "./../assets/img/brokerlogoclient/Fxpro logo.svg";

import IG from "./../assets/img/brokerlogoclient/IG Logo.jpg";

import peperstone from "./../assets/img/brokerlogoclient/Pepperstone logo.png";
import { GoGitCompare } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
// import { LoaderContext } from "../Context/Loader";

const Header = () => {
  const Navigate = useNavigate()
  const FrontClick = ()=>{
    Navigate("/")
    window.scrollTo(0, 0);
  }
  // const [sidebar, setSidebar] = useState(false);
  const [MenuOpen, setMenuOpen] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isDropdownVisible2, setDropdownVisible2] = useState(false);
  const [isDropdownVisible3, setDropdownVisible3] = useState(false);
  const [isDropdownVisible4, setDropdownVisible4] = useState(false);
  const [isDropdownVisible5, setDropdownVisible5] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
    setDropdownVisible2(false);
    setDropdownVisible3(false);
    setDropdownVisible4(false);
    setDropdownVisible5(false);
  };
  const toggleDropdown3 = () => {
    setDropdownVisible3(!isDropdownVisible3);
    setDropdownVisible(false);
    setDropdownVisible2(false);
    setDropdownVisible4(false);
    setDropdownVisible5(false);
  };
  const toggleDropdown4 = () => {
    setDropdownVisible4(!isDropdownVisible4);
    setDropdownVisible(false);
    setDropdownVisible2(false);
    setDropdownVisible3(false);
    setDropdownVisible5(false);
  };
  const toggleDropdown5 = () => {
    setDropdownVisible5(!isDropdownVisible5);
    setDropdownVisible(false);
    setDropdownVisible2(false);
    setDropdownVisible3(false);
    setDropdownVisible4(false);
  };
  const toggleDropdown2 = () => {
    setDropdownVisible2(!isDropdownVisible2);
    setDropdownVisible(false);
    setDropdownVisible3(false);
    setDropdownVisible4(false);
    setDropdownVisible5(false);
  };
  const HandleToggle = () => {
    setMenuOpen(!MenuOpen);
    console.log("ssss");
  };
  return (
    <>
      <div className="main-head-wrap ">

        <div className="container">
          <div
            className={`menu-overlay ${MenuOpen ? "active" : ""}`}
            onClick={HandleToggle}
          ></div>
          <div
            className={`menu-overlay ${isDropdownVisible ? "active" : ""}`}
            onClick={toggleDropdown}
          ></div>
          <div
            className={`menu-overlay ${isDropdownVisible2 ? "active" : ""}`}
            onClick={toggleDropdown2}
          ></div>
          <div
            className={`menu-overlay ${isDropdownVisible3 ? "active" : ""}`}
            onClick={toggleDropdown3}
          ></div>
          <div
            className={`menu-overlay ${isDropdownVisible4 ? "active" : ""}`}
            onClick={toggleDropdown4}
          ></div>
          <div
            className={`menu-overlay ${isDropdownVisible5 ? "active" : ""}`}
            onClick={toggleDropdown5}
          ></div>

          <div className="row mb-r">
            <div className=" col-md-3 d-flex align-items-center mb-col  mb-mt" onClick={FrontClick}>
              <Link to="/" className=" mb-pos">
                {" "}
                <img
                  src={logo}
                  alt=""
                  srcset=""
                  style={{ width: "281px", height: "41px" }}
                />
              </Link>
            </div>
            <div className="col-md-9  item-wrawp">
              <ul className="d-flex gap-2 align-items-center mb-0 item-wrawp">
                {/* <li  className="nav-item">
                    {" "}
                    <Button variant="outline-light p-1">Find My Broker</Button>
                  </li> */}
                <li
                  className={`menu-item ${isDropdownVisible ? "active" : ""}`}
                >
                  {" "}
                  <NavLink onClick={toggleDropdown}>
                    Broker By Country{" "}
                    <AiOutlineDown
                      className={isDropdownVisible ? "rotate-180" : "rotate-0"}
                    />
                  </NavLink>
                  <div
                    className={`nestedmenu ${isDropdownVisible ? "show" : ""}`}
                  >
                    <div className="container-fluid">
                      <div className="d-flex gap-5 rounded-2">
                        {/* <ul className="">
                            <h5
                              className="text-left nested-head "
                              style={{
                                fontSize: "13px",
                                color: "#000",
                                fontWeight: "600",
                                textAlign: "left",
                                padding: "0 15px",

                                opacity: "1",
                              }}
                            >
                              Best broker by category
                            </h5>
                            <hr style={{ color: "gray", margin: "0" }} />

                            <li  className=" text-slate-800 cursor-pointer">
                              {" "}
                              Best brokers for beginners
                            </li>
                            <li  className=" text-slate-800 cursor-pointer">
                              {" "}
                              Best stock brokers
                            </li>
                            <li  className=" text-slate-800 cursor-pointer">
                              {" "}
                              Best forex brokers
                            </li>
                            <li  className=" text-slate-800 cursor-pointer">Best CFD brokers</li>
                            <li  className=" text-slate-800 cursor-pointer">
                              Best futures brokers
                            </li>
                            <li  className=" text-slate-800 cursor-pointer">
                              {" "}
                              Best low spread forex brokers
                            </li>
                            <li  className=" text-slate-800 cursor-pointer">
                              {" "}
                              Best crypto brokers
                            </li>
                            <li  className=" text-slate-800 cursor-pointer">
                              {" "}
                              Best stock trading apps
                            </li>
                            <li  className=" text-slate-800 cursor-pointer">
                              {" "}
                              Best discount brokers
                            </li>
                            <li  className=" text-slate-800 cursor-pointer">
                              {" "}
                              Best brokers for bonds
                            </li>
                          </ul> */}
                        <ul className="">
                          <h5
                            className="text-left nested-head text-dark"
                            style={{
                              fontSize: "13px",
                              color: "#000",
                              fontWeight: "600",
                              textAlign: "left",

                              opacity: "1",
                            }}
                          >
                            {" "}
                            Country-specific recommendation
                          </h5>
                          <hr style={{ color: "gray", margin: "0" }} />
                          {/* <li className="cursor-pointer">
                              {" "}
                              <img
                                class="h-6 w-6 pb-1"
                                alt="amreica"
                                src={flag1}
                              />
                              United States
                            </li> */}
                          <li className="cursor-pointer">
                            {" "}
                            <img
                              class="h-6 w-6 pb-1"
                              alt="amreica"
                              src={flag3}
                            />
                            Singapore
                          </li>
                          <li className="cursor-pointer">
                            {" "}
                            <img
                              class="h-6 w-6 pb-1"
                              alt="amreica"
                              src={flag5}
                            />
                            UAE
                          </li>
                          <li className="cursor-pointer">
                            {" "}
                            <img
                              class="h-6 w-6 pb-1"
                              alt="amreica"
                              src={flag6}
                            />
                            United Kingdom
                          </li>
                          <li className="cursor-pointer">
                            {" "}
                            <img
                              class="h-6 w-6 pb-1"
                              alt="amreica"
                              src={flag7}
                            />
                            Germany
                          </li>
                          <li className="cursor-pointer">
                            {" "}
                            <img
                              class="h-6 w-6 pb-1"
                              alt="amreica"
                              src={flag8}
                              width={"24px"}
                              height={"24px"}
                            />
                            South Africa
                          </li>
                          <li className="cursor-pointer">
                            <img
                              class="h-6 w-6 pb-1"
                              alt="amreica"
                              src={flag2}
                            />
                            Australia
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  className={`menu-item ${isDropdownVisible2 ? "active" : ""}`}
                >
                  {" "}
                  <NavLink onClick={toggleDropdown2}>
                    Brokers reviews{" "}
                    <AiOutlineDown
                      className={isDropdownVisible2 ? "rotate-180" : "rotate-0"}
                    />
                  </NavLink>
                  <div
                    className={`nestedmenu-2 border-radious-bottom ${isDropdownVisible2 ? "show" : ""
                      }`}
                  >
                    <div className="container-fluid">
                      <div className="d-flex flex-column">
                        <div className="mested2-wrap">
                          <div className="d-flex gap-5 rounded-2">
                            <div>
                              <h5
                                className="text-left nested-head text-dark"
                                style={{
                                  fontSize: "13px",
                                  color: "#000",
                                  fontWeight: "600",
                                  textAlign: "left",

                                  opacity: "1",
                                }}
                              >
                                {" "}
                                Recomended Brokers
                              </h5>
                              <hr style={{ color: "gray", margin: "0" }} />
                              <div className="d-flex gap-5">
                                <ul
                                  className="pr-5 d-flex flex-column"
                                  style={{
                                    paddingLeft: "0px",

                                  }}
                                >
                                  <li className="cursor-pointer" onClick={() => Navigate("/reviewdetail")}>
                                    {" "}
                                    <img
                                      class="h-6 w-6  rounded-2"
                                      alt="amreica"
                                      width={"32px"}
                                      height={"32px"}
                                      src={plus500}
                                    />
                                    <p style={{ marginTop: "0px", marginBottom: "0px" }}> Plus500</p>
                                  </li>
                                  <li className="cursor-pointer" onClick={() => Navigate("/activtraders-review")}>
                                    {" "}
                                    <img
                                      width={"32px"}
                                      height={"32px"}
                                      class="h-6 w-6  rounded-2"
                                      alt="amreica"
                                      src={activetrade}
                                    />{" "}
                                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>
                                      ActivTrades
                                    </p>

                                  </li>
                                  <li className="cursor-pointer" onClick={() => Navigate("/xtb-review")}>
                                    {" "}
                                    <img
                                      width={"32px"}
                                      height={"32px"}
                                      class="h-6 w-6  rounded-2"
                                      alt="amreica"
                                      src={XTB}
                                    />
                                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>  XTB</p>

                                  </li>
                                  <li className="cursor-pointer" onClick={() => Navigate("/ig-review")}>
                                    {" "}
                                    <img
                                      width={"32px"}
                                      height={"32px"}
                                      class="h-6 w-6  rounded-2"
                                      alt="amreica"
                                      src={IG}
                                    />{" "}
                                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>  IG</p>

                                  </li>
                                  <li className="cursor-pointer" onClick={() => Navigate("/pepperstone-review-2")}>
                                    {" "}
                                    <img
                                      width={"32px"}
                                      height={"32px"}
                                      class="h-6 w-6  rounded-2"
                                      alt="amreica"
                                      src={peperstone}
                                    />
                                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>  Pepperstone</p>

                                  </li>
                                </ul>
                                <ul
                                  className="pr-5 d-flex flex-column"
                                  style={{
                                    paddingLeft: "0px",
                                  }}
                                >
                                  <li className="cursor-pointer" onClick={() => Navigate("/forex-com-review")}>
                                    {" "}
                                    <img
                                      width={"32px"}
                                      height={"32px"}
                                      class="h-6 w-6  rounded-2"
                                      alt="amreica"
                                      src={forexcom}
                                    />{" "}
                                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>  FOREX.com	</p>

                                  </li>
                                  <li className="cursor-pointer" onClick={() => Navigate("/fxpro-review")}>
                                    <img
                                      width={"32px"}
                                      height={"32px"}
                                      class="h-6 w-6  rounded-2"
                                      alt="amreica"
                                      src={fxcmlogo}
                                    />{" "}
                                    <p style={{ marginTop: "0px", marginBottom: "0px" }}> Fxpro</p>

                                  </li>
                                  <li className="cursor-pointer" onClick={() => Navigate("/city-index-review")}>
                                    <img
                                      width={"32px"}
                                      height={"32px"}
                                      class="h-6 w-6  rounded-2"
                                      alt="amreica"
                                      src={cityindex}
                                    />
                                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>   City Index	</p>

                                  </li>
                                  <li className="cursor-pointer" onClick={() => Navigate("/cmc-markets-review")}>
                                    <img
                                      width={"32px"}
                                      height={"32px"}
                                      class="h-6 w-6  rounded-2"
                                      alt="amreica"
                                      src={review10}
                                    />{" "}
                                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>CMC Markets	</p>

                                  </li>
                                  <li className="cursor-pointer" onClick={() => Navigate("/")}>
                                    <img
                                      width={"32px"}
                                      height={"32px"}
                                      class="h-6 w-6  rounded-2"
                                      alt="amreica"
                                      src={review8}
                                    />{" "}
                                    <p style={{ marginTop: "0px", marginBottom: "0px" }}> Tickmill</p>

                                  </li>



                                </ul>


                              </div>
                              <div className="d-flex justify-content-center py-3">
                                <Link to="/allreviews" rel="noopener noreferrer">
                                  <button className="btn btn-primary rounded-2 w-100 px-4 " style={{
                                    background: "#011dff"
                                  }}>
                                    ALL REVIEWS
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  className={`menu-item ${isDropdownVisible3 ? "active" : ""}`}
                >
                  <NavLink onClick={toggleDropdown3}>
                    Tools{" "}
                    <AiOutlineDown
                      className={isDropdownVisible3 ? "rotate-180" : "rotate-0"}
                    />
                  </NavLink>
                  <div className="container-fluid ">
                    <div
                      className={`nestedmenu-3 ${isDropdownVisible3 ? "show" : ""
                        }`}
                    >
                      <div className="d-flex gap-5 rounded-2">
                        <div className="p-2">
                          <ul
                            className="pl-0"
                            style={{
                              paddingLeft: "0px",
                            }}
                          >
                            <li className="cursor-pointer" style={{marginTop:"10px"}}>
                              <div className="d-flex gap-2">
                                <img
                                  class="h-6 w-6 pb-1"
                                  alt="amreica"
                                  width={"50px"}
                                  height={"50px"}
                                  style={{
                                    background: "#011dff",
                                    borderRadius: "10px",
                                    padding: "5px 15px",
                                    marginTop:"-10px"
                                  }}
                                  src={searchicon1}
                                />
                                <div className="d-bloc">
                                  <h5 className="smallh  " style={{ marginTop: "2px" }}> Find My Broker</h5>
                                  <p className="smallp">
                                    {" "}

                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="cursor-pointer" style={{marginTop:"10px"}}>
                              <div className="d-flex gap-2">
                                {" "}
                                <div className="icontool" style={{marginTop:"-10px"}}>
                                  {" "}
                                  <GoGitCompare />
                                </div>
                                <Link to={"/comparebroker"}>
                                  <div className="d-bloc">

                                    <h5 className="smallh " style={{ marginTop: "2px" }}> Compare Broker</h5>
                                    <p className="smallp">
                                      {" "}

                                    </p>

                                  </div>
                                </Link>
                              </div>
                            </li>

                            <li className="cursor-pointer" style={{marginTop:"10px"}}>
                              <div className="d-flex gap-2">
                                {" "}
                                <img
                                  width={"50px"}
                                  height={"50px"}
                                  class="h-6 w-6 pb-1 rounded-3"
                                  alt="amreica"
                                  src={searchicon4}
                                  style={{ background: "#011dff", marginTop:"-10px"}}
                                />{" "}
                                <Link to={"/feeCalculator"}>
                                  <div className="d-block">
                                    <h5 className="smallh " style={{ marginTop: "2px" }}> FX Fee Calculator</h5>
                                    <p className="smallp">
                                      {" "}

                                    </p>
                                  </div>
                                </Link>
                              </div>
                            </li>

                            <li className="cursor-pointer" style={{marginTop:"10px"}}>
                              <div className="d-flex gap-2">
                                <img
                                  width={"50px"}
                                  height={"50px"}
                                  class="h-6 w-6 pb-1 rounded-3"
                                  alt="amreica"
                                  style={{ background: "#011dff" ,marginTop:"-10px"}}
                                  src={searchicon7}
                                />{" "}
                                <div className="d-block">
                                  <h5 className="smallh " style={{ marginTop: "2px" }}>
                                    {" "}
                                    Scam Broker Shield
                                  </h5>
                                  <p className="smallp">
                                    {" "}

                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  className={`menu-item cursor-pointer ${isDropdownVisible4 ? "active" : ""
                    }`}
                >
                  {" "}
                  <NavLink onClick={toggleDropdown4}>
                    For beginners{" "}
                    <AiOutlineDown
                      className={isDropdownVisible4 ? "rotate-180" : "rotate-0"}
                    />
                  </NavLink>
                  <div
                    className={`nestedmenu-4 ${isDropdownVisible4 ? "show" : ""
                      }`}
                  >
                    <div className="d-flex gap-5 rounded-2">
                      <ul
                        className="pl-0"
                        style={{
                          paddingLeft: "0px",
                        }}
                      >
                        <li className="text-slate-800 cursor-pointer">
                          Best Online Broker
                        </li>
                        <li className="text-slate-800 cursor-pointer">
                          Scam broker check
                        </li>

                        <li className="text-slate-800 cursor-pointer">
                          Forex trading{" "}
                        </li>
                        <li className="text-slate-800 cursor-pointer">
                          CFD trading{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li
                  className={`menu-item ${isDropdownVisible5 ? "active" : ""}`}
                >
                  {" "}
                  <NavLink onClick={toggleDropdown5}>
                    About us{" "}
                    <AiOutlineDown
                      className={isDropdownVisible5 ? "rotate-180" : "rotate-0"}
                    />
                  </NavLink>
                  <div
                    className={`nestedmenu-5 ${isDropdownVisible5 ? "show" : ""
                      }`}
                  >
                    <div className="d-flex gap-5 rounded-2">
                      <ul
                        className="pl-0"
                        style={{
                          paddingLeft: "0px",
                        }}
                      >
                        <li className="text-slate-800 cursor-pointer">
                          Our Team
                        </li>

                        <li className="text-slate-800 cursor-pointer">
                          For partner{" "}
                        </li>
                        <li className="text-slate-800 cursor-pointer">
                          Our methodology{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                {/* <li  className="menu-item">
                    {" "}
                    <NavLink>
                      {" "}
                      <AiOutlineSearch />
                    </NavLink>
                  </li>
                  <li  className="menu-item">
                    {" "}
                    <NavLink>
                      <RxAvatar />
                    </NavLink>
                  </li> */}
              </ul>
            </div>
            <div className="mainitem">
              <FaBars className="burger" onClick={HandleToggle} />
            </div>
          </div>
        </div>

        <ResponsiveNavbar MenuOpen={MenuOpen} />

      </div>

    </>
  );
};

export default Header;
