import React from 'react'
import { AiOutlineStar } from 'react-icons/ai'
import { GoGitCompare } from 'react-icons/go'
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { BsCalculator } from 'react-icons/bs'
// import besticon from "./../assets/img/best_brokers_icon.svg"
// import reviewbroker from "./../assets/img/review.svg"
// import comparebroker from "./../assets/img/compare.svg"
// import feecalculater from "./../assets/img/calculater.svg"

const HomeTools = () => {
  return (
    <div className='hometools py-5' >
      <h1 className="text-center font-bold px-4 " style={{
            fontSize:" 24px",
            lineHeight: "2.25rem",
            color:" #020617",
    fontFamily:" Geomanist,sans-serif"
      }}>
       Tools to find your broker quickly
      </h1>
      <div className="container">
        <div className="row"> 
            <div className="col-md-3  p-3">

           <div className="d-flex align-items-center gap-2 wrap forhov hn cursorimp">
  <div className="icontool">
    <AiOutlineStar/>
  </div>
            <div className="flex-column justify-content-center">
                <h6 className='fontmain ' style={{
                    fontSize:"20px",
                    textAlign:"left",
                    marginBottom:"0%",
                    paddingBottom:"0%"
                }}>Best List</h6>
                <p style={{
                    textAlign:"left",
                    fontSize:"12px",
                    marginBottom:"0%",
                    paddingBottom:"0%"
                }}></p>
            </div>
           </div>
            </div>
            <div className="col-md-3  p-3">

           <div className="d-flex align-items-center gap-2 wrap forhov hn cursorimp ">
            <div className="icontool">    <GoGitCompare/></div>
        
            <div className="flex-column justify-content-center">
                <h6 className='fontmain ' style={{
                    fontSize:"20px",
                    textAlign:"left",
                    marginBottom:"0%",
                    paddingBottom:"0%"
                }}>Compare broker</h6>
                <p style={{
                    textAlign:"left",
                    fontSize:"12px",
                    marginBottom:"0%",
                    paddingBottom:"0%"
                }}>
      
                </p>
            </div>
           </div>
            </div>
            <div className="col-md-3  p-3">

<div className="d-flex align-items-center gap-2 wrap forhov hn cursorimp">
<div className="icontool">
<HiOutlineDocumentReport/>
</div>
 <div className="flex-column justify-content-center">
     <h6 className='fontmain ' style={{
         fontSize:"20px",
         textAlign:"left",
         marginBottom:"0%",
         paddingBottom:"0%"
     }}>Broker reviews</h6>
     <p style={{
         textAlign:"left",
         fontSize:"12px",
         marginBottom:"0%",
         paddingBottom:"0%"
     }}>  
     </p>
 </div>
</div>
 </div>  <div className="col-md-3  p-3">

<div className="d-flex align-items-center gap-2 wrap forhov hn cursorimp ">
  <div className="icontool">
  <BsCalculator/>
  </div>

 <div className="flex-column justify-content-center">
     <h6 className='fontmain ' style={{
         fontSize:"20px",
         textAlign:"left",
         marginBottom:"0%",
         paddingBottom:"0%"
     }}>  
     Fee calculator
   </h6>
     <p style={{
         textAlign:"left",
         fontSize:"12px",
         marginBottom:"0%",
         paddingBottom:"0%"
     }}>
   </p>
 </div>
</div>
 </div>
            
        </div>
      </div>
    </div>
  )
}

export default HomeTools
