/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-sparse-arrays */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
// import loadergif from './../assets/img/Loader.gif'
import securites from "./../assets/img/brokerlogoclient/ACY Securities logo.jpg";
import admiralmarket from "./../assets/img/brokerlogoclient/Admirals Markets logo.png";
import avatrade from "./../assets/img/brokerlogoclient/AvaTrade logo.jpg";
import axi from "./../assets/img/brokerlogoclient/Axi logo.png";
import blackbull from "./../assets/img/brokerlogoclient/Blackbull logo.png";
import captial from "./../assets/img/brokerlogoclient/Capital.com logo2.png";
import cityindex from "./../assets/img/brokerlogoclient/City Index logo.png";
import Eightcap from "./../assets/img/brokerlogoclient/EightCap logo.png";
import etoro from "./../assets/img/brokerlogoclient/eToro logo.png";
import forexcom from "./../assets/img/brokerlogoclient/forex.com logo.png";
import fpmarket from "./../assets/img/brokerlogoclient/FP Markets logo.png";
import fusionmarket from "./../assets/img/brokerlogoclient/Fusion Markets logo.jpg";
import fxcmlogo from "./../assets/img/brokerlogoclient/FXCM logo.png";
import fxpro from "./../assets/img/brokerlogoclient/Fxpro logo.svg";
import fxtm from "./../assets/img/brokerlogoclient/FXTM logo2.png";
import globalprime from "./../assets/img/brokerlogoclient/Global Prime Logo.png";
import gomarket from "./../assets/img/brokerlogoclient/Go Markets logo.jpeg";
import HFmarket from "./../assets/img/brokerlogoclient/HF Markets.png";
import Hycmlogo from "./../assets/img/brokerlogoclient/HYCM logo.png";
import ICmarket from "./../assets/img/brokerlogoclient/IC Markets logo2.png";
import IG from "./../assets/img/brokerlogoclient/IG Logo.jpg";
import marketcom from "./../assets/img/brokerlogoclient/Markets.com logo.jpeg";
import monetamarket from "./../assets/img/brokerlogoclient/Moneta Markets logo2.png";
import multibank from "./../assets/img/brokerlogoclient/MultiBank logo.svg";
import oandalogo from "./../assets/img/brokerlogoclient/Oanda Logo.jpg";
import peperstone from "./../assets/img/brokerlogoclient/Pepperstone logo.png";
import swisslogo from "./../assets/img/brokerlogoclient/Swissquote logo.png";
import tickmil from "./../assets/img/brokerlogoclient/Tickmill logo.png";
import tmgm from "./../assets/img/brokerlogoclient/TMGM logo.jpeg";
import tradenation from "./../assets/img/brokerlogoclient/Trade Nation logo.png";
import vintage from "./../assets/img/brokerlogoclient/Vantage logo.png";
import xtm from "./../assets/img/brokerlogoclient/XM logo.jpg";
import activetrade from "./../assets/img/main-icon.jpg";
import plus500 from "./../assets/img/brokerlogoclient/Plus500 logo2.png";
import XTB from "./../assets/img/Xtb-logo.png";

import CMCmarketlogo from "./../assets/img/brokerlogoclient/CMC Markets Logo.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { LuExternalLink } from "react-icons/lu";
import Instance from "../utils/Interceptor";

const HighestRatedBroker = () => {
  // const [searchValue] = useState("");
  const [filteredBrokers, setFilteredBrokers] = useState([]);
  const [BrokerData, setBrokerData] = useState([]);
  const [Loader, setLoader] = useState(false);

  

  const [SelectCountry, setSelectedCountry] = useState();

  const countryCodes = {
    AF: "Afghanistan",
    AE: "United Arab Emirates",

    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    BH: "Bahrain",
    BD: "Bangladesh",
    BE: "Belgium",
    BR: "Brazil",
    CN: "China",
    CY: "Cyprus",
    DK: "Denmark",
    EG: "Egypt",
    FI: "Finland",
    FR: "France",
    DE: "Germany",
    HK: "Hong Kong",
    IE: "Ireland",
    IL: "Israel",
    IT: "Italy",
    JP: "Japan",
    KW: "Kuwait",
    LU: "Luxembourg",
    NL: "Netherlands",
    NZ: "New Zealand",
    OM: "Oman",
    PL: "Poland",
    QA: "Qatar",
    SA: "Saudi Arabia",
    SG: "Singapore",
    ZA: "South Africa",
    ES: "Spain",
    SE: "Sweden",
    CH: "Switzerland",
    UAE: "United Arab Emirates",
    GB: "United Kingdom",
    PK: "AllCountry",
    US: "AllCountry",
    RU: "AllCountry",
    TR: "AllCountry",
    IN: "AllCountry",
    CA: "AllCountry",
    MX: "AllCountry",
    NG: "AllCountry",
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await Instance.get("api/country-code");
        const country = response.data.country;
        const fullCountry = countryCodes[country];
        const condition =
          fullCountry && Object.values(countryCodes).includes(fullCountry)
            ? fullCountry
            : "AllCountry";
        setSelectedCountry(condition);
        setLoader(true);
      } catch (error) {
        console.error("Error fetching country code:", error);
      }
    };

    const fetchBrokerData = async () => {
      setLoader(true);
      try {
        const response = await Instance.get("api/highest");
        const brokerData = response.data.data.slice(0, 36);
        setBrokerData(brokerData);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching broker data:", error);
      }
      setLoader(false);
    };

    fetchData();
    fetchBrokerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let newdata = BrokerData.filter((broker) =>
    broker.country?.includes(SelectCountry)
  );
  newdata = newdata.slice(0, 3);
  console.log(newdata);
  console.log(filteredBrokers, "filteredBrokers");
  const filterBrokers = (searchText) => {
    console.log(filterBrokers);
    const filteredResults = newdata.filter((broker) =>
      broker.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredBrokers(filteredResults);
  };

  const minRating = 0.1;
  const maxRating = 5.0;

  return (
    <>
      {Loader ? (
        <div className="Loader"></div>
      ) : (
        <>
          <section
            className="brokerlist py-5"
            style={{ backgroundColor: "#f4f8fe" }}
          >
            <div className="container">
              <div className="row">
                <h2
                  className="text-center"
                  style={{ fontSize: "32px", fontWeight: "600" }}
                >
                  Best Brokers For {SelectCountry}
                </h2>
                <div className="row gy-5 gx-2 " style={{ marginLeft: "2px" }}>
                  {Loader ? (
                    <div className="Loader"></div>
                  ) : (
                    newdata?.map((e, i) => (
                      <div
                        key={i}
                        className="col-lg-4 pricing-block style-two animated fadeInUp"
                        data-wow-delay="200ms"
                        data-wow-duration="1200ms"
                      >
                        <div className="inner-box">
                          <div className="lower-content">
                            {e?.recommended && (
                              <h5>{e?.recommended && "recommended"}</h5>
                            )}
                            <h4 style={{ marginTop: "0px" }}>
                              <span
                                onClick={() => window.open(e?.url, "_blank")}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={
                                    e?.broker_img === "securites"
                                      ? securites
                                      : e?.broker_img === "admiralmarket"
                                      ? admiralmarket
                                      : e?.broker_img === "avatrade"
                                      ? avatrade
                                      : e?.broker_img === "axi"
                                      ? axi
                                      : e?.broker_img === "blackbull"
                                      ? blackbull
                                      : e?.broker_img === "captial "
                                      ? captial
                                      : e?.broker_img === "cityindex"
                                      ? cityindex
                                      : e?.broker_img === "Eightcap"
                                      ? Eightcap
                                      : e?.broker_img === "etoro"
                                      ? etoro
                                      : e?.broker_img === "forexcom"
                                      ? forexcom
                                      : e?.broker_img === "fpmarket"
                                      ? fpmarket
                                      : e?.broker_img === "fusionmarket"
                                      ? fusionmarket
                                      : e?.broker_img === "fxcmlogo"
                                      ? fxcmlogo
                                      : e?.broker_img === "Fxpro logo"
                                      ? fxpro
                                      : e?.broker_img === "FXTM "
                                      ? fxtm
                                      : e?.broker_img === "globalprime"
                                      ? globalprime
                                      : e?.broker_img === "gomarket"
                                      ? gomarket
                                      : e?.broker_img === "HFmarket"
                                      ? HFmarket
                                      : e?.broker_img === "Hycmlogo"
                                      ? Hycmlogo
                                      : e?.broker_img === "ICmarket"
                                      ? ICmarket
                                      : e?.broker_img === "IG"
                                      ? IG
                                      : e?.broker_img === "marketcom"
                                      ? marketcom
                                      : e?.broker_img === "monetamarket"
                                      ? monetamarket
                                      : e?.broker_img === "multibank"
                                      ? multibank
                                      : e?.broker_img === "oandalogo"
                                      ? oandalogo
                                      : e?.broker_img === "peperstone"
                                      ? peperstone
                                      : e?.broker_img === "swisslogo"
                                      ? swisslogo
                                      : e?.broker_img === "tickmil"
                                      ? tickmil
                                      : e?.broker_img === "tmgm"
                                      ? tmgm
                                      : e?.broker_img === "tradenation"
                                      ? tradenation
                                      : e?.broker_img === "vintage"
                                      ? vintage
                                      : e?.broker_img === "xtm"
                                      ? xtm
                                      : e?.broker_img === "plus500"
                                      ? plus500
                                      : e?.broker_img === "activetrade"
                                      ? activetrade
                                      : e?.broker_img === "XTB"
                                      ? XTB
                                      : e?.broker_img === "CMCmarketlogo"
                                      ? CMCmarketlogo
                                      : ""
                                  }
                                  width={"77"}
                                  height={"77"}
                                  style={{
                                    borderRadious: "10px",
                                    boxShadow: "5px 5px 10px rgba(0,0,0,.23)",
                                  }}
                                  alt=""
                                  srcset=""
                                  className="mb-2 rounded-2"
                                />
                              </span>
                              <br />
                              <span style={{ fontSize: "15px" }}>
                                {e?.name}
                              </span>
                            </h4>
                            <div className="d-flex py-3 gap-2 justify-content-center  align-items-center setgap flex-reverse ">
                              <h3
                                className="mt-2"
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {e?.ratting}
                              </h3>
                              <div className=" d-flex justify-content-center">
                                <div class="ratings">
                                  <div class="empty-stars"></div>
                                  <div
                                    className="full-stars"
                                    style={{
                                      width: `${
                                        ((e?.ratting - minRating) /
                                          (maxRating - minRating)) *
                                        100
                                      }%`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                className={`py-2 mb-4`}
                                style={{ height: "92px" }}
                              >
                                <div className="grid grid-cols-2 text-sm">
                                  <div className="text-slate-600 text-left">
                                    {" "}
                                    Min. Deposit:
                                  </div>
                                  <div className="mr-4 flex">
                                    {" "}
                                    {e?.min_deposit}
                                  </div>
                                </div>
                                <div className="grid grid-cols-2 text-sm">
                                  <div className="text-slate-600 text-left">
                                    {" "}
                                    Max. Leverage
                                  </div>
                                  <div className="mr-4 flex">
                                    {e?.max_leverage}{" "}
                                  </div>
                                </div>
                                <div className="grid grid-cols-2 text-sm">
                                  <div className="text-slate-600 text-left">
                                    Trading Platform{" "}
                                  </div>

                                  <div className="mr-4 flex">{e?.platform}</div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="link-btn"
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                onClick={() =>
                                  window.open(`${e?.url}`, "_blank")
                                }
                                className="theme-btn btn-style-two"
                              >
                                <span className="btn-title ">
                                  VISIT BROKER{" "}
                                  <LuExternalLink
                                    size="22"
                                    style={{
                                      paddingBottom: "5px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </span>
                              </span>
                            </div>
                            <div className="hint">
                              <Link to={e?.path} className="forhover">
                                {" "}
                                <span
                                  style={{ borderBottom: "1px solid #0a2cd4" }}
                                >
                                  Read review
                                </span>{" "}
                              </Link>
                              <p className="tradepara py-1 mt-2 mb-0">
                                {e?.lose}% of retail CFD accounts lose money
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}

                  

                 
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default HighestRatedBroker;
