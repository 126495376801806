import React, { useEffect, useState } from 'react'
// import logo from "./../assets/img/Get forex-01 (2).png";
import review1 from "./../assets/img/broker_logos/interactive-brokers-review.png";
import review2 from "./../assets/img/broker_logos/alpaca-trading-review.png";
import review3 from "./../assets/img/broker_logos/admirals-admiral-markets-review.png";
import review4 from "./../assets/img/broker_logos/ig-review.png";
import review5 from "./../assets/img/broker_logos/ic-markets-review.png";
import review6 from "./../assets/img/broker_logos/pepperstone-review.png";
import review7 from "./../assets/img/broker_logos/fusion-markets-review.png";
import review8 from "./../assets/img/broker_logos/xm-review.png";
import review9 from "./../assets/img/broker_logos/mexem-review.png";
import review10 from "./../assets/img/broker_logos/fxcm-review.png";
import searchicon1 from "./../assets/img/icons/search-icon.svg";
import searchicon2 from "./../assets/img/icons/compare_icon_box.svg";

import searchicon4 from "./../assets/img/icons/fx_fee_calculator_icon.svg";

import searchicon7 from "./../assets/img/icons/scam_shield_icon.svg";
import { BiArrowBack } from "react-icons/bi"
import { AiOutlineArrowRight } from 'react-icons/ai';
// import { GoMoveToBottom } from "react-icons/go";
import arrow from '../assets/img/arrow1.png'
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
const ResponsiveNavbar = ({ MenuOpen }) => {


    const [bestbroker, setbestbroker] = useState(false);
    // const[toolsOpen,SetToolsOpen]=useState(false)
    // // const [company,SetCompanyOpen]=useState(false)
    const [brokerreview, setbrokerreview] = useState(false)
    const [Tools, setTools] = useState(false)
    const [for_begginer, setfor_begginer] = useState(false)
    const [Aboutus, setAboutus] = useState(false)

    // const [ForBeginner,setBeginner]=useState(false)
    // const[AboutUs,setAboutUS]=useState(false)



    useEffect(() => {

        if (!MenuOpen) {

            setbestbroker(false);

            setbrokerreview(false);
            setTools(false);


            //   setBeginner(false);
            //   setAboutUS(false);

        }
    }, [MenuOpen]);


    const bestbrokerOpen = () => {

        setbestbroker(!bestbroker)

    }
    const rokerreviewOpen = () => {
        setbrokerreview(!brokerreview)
    }
    const ToolsOpen = () => {
        setTools(!Tools)
    }
    const beginneropen = () => {
        setfor_begginer(!for_begginer)
    }
    const aboutOpen = () => {
        setAboutus(!Aboutus)
    }

    // const ForBeginnerOpen=()=>{
    //     setBeginner(!ForBeginner)
    // }
    // const AboutUsOpen=()=>{
    //     setAboutUS(!AboutUs)    

    //     }


    return (
        <div className={`menu-main mobilemenu clearfix sidebar ${MenuOpen ? 'open' : ''}`} >
            <nav className="menu new-nav">

                {/* <a
            to="#"
            data-fancybox=""
            data-modal="true"
            data-src="#modal-login"
        >
            {" "}
            Register{" "}
        </a > */}
                {/*

        
    <div className="sidebar-logo py-4 ">
    <Link to="/" >
    <img src={logo} alt="" srcset="" style={{    width: "221px",
    height: "41px"
}} />
</Link>
    </div>
*/}


                <ul className="MyMenu">
                    <li>

                        <span className="toggle noatag-need" onClick={bestbrokerOpen}>Best Broker  <AiOutlineArrowRight  style={{marginRight:"20px",float:"right"}}/></span>
                        {MenuOpen && bestbroker && <div
                            className={`services_menu inner_menu sm ${bestbroker ? 'open' : ''}`}
                        //   style={{ left: servicesOpen ? '0px' : '-100%',  transition: servicesOpen ? 'left 0.5s steps(10, end)' : 'none'

                        //             }}
                        >
                            <span className="back_menu  " onClick={() => {
                                setbestbroker(false)
                            }}>
                                {" "}
                                <BiArrowBack style={{ color: "#fff" }} />
                                <span className='text-white ms-2 mt-4'>Back</span>
                            </span>
                            <ul className="">
                                <h5
                                    className="text-left nested-head "
                                    style={{
                                        fontSize: "13px",
                                        color: "#000",
                                        fontWeight: "600",
                                        textAlign: "left",
                                        padding: "0 15px",

                                        opacity: "1",
                                    }}
                                >
                                    Best broker by category
                                </h5>
                                <hr style={{ color: "gray", margin: "0" }} />

                                <li className=" text-slate-800">
                                    {" "}
                                    Best brokers for beginners
                                </li>
                                <li className=" text-slate-800">
                                    {" "}
                                    Best stock brokers
                                </li>
                                <li className=" text-slate-800">
                                    {" "}
                                    Best forex brokers
                                </li>
                                <li className=" text-slate-800">Best CFD brokers</li>
                                <li className=" text-slate-800">
                                    Best futures brokers
                                </li>
                                <li className=" text-slate-800">
                                    {" "}
                                    Best low spread forex brokers
                                </li>
                                <li className=" text-slate-800">
                                    {" "}
                                    Best crypto brokers
                                </li>
                                <li className=" text-slate-800">
                                    {" "}
                                    Best stock trading apps
                                </li>
                                <li className=" text-slate-800">
                                    {" "}
                                    Best discount brokers
                                </li>
                                <li className=" text-slate-800">
                                    {" "}
                                    Best brokers for bonds
                                </li>
                            </ul>

                        </div>}

                    </li>
                    <li>
                        <span className="toggle noatag-need" onClick={rokerreviewOpen}>
                            Broker Reviews <img src={arrow} alt="img" style={{width:"20px", float:"right",marginRight:"20px"}} />
                        </span>
                        {MenuOpen && brokerreview && (
                            <div
                                className={`services_menu inner_menu sm ${brokerreview ? 'open' : ''}`}
                            >
                                <>
                                    <span className="back_menu" onClick={() => { setbrokerreview(false) }}>
                                        <BiArrowBack style={{ color: "#fff" }} />
                                        <span className='text-white ms-2 mt-4'>Back</span>
                                    </span>
                                </>
                                <ul className="pl-0" style={{ paddingLeft: "0px" }}>
                                    <h5 className="text-left nested-head text-dark" style={{ fontSize: "13px", color: "#fff !important", fontWeight: "600", textAlign: "center", opacity: "1" }}>
                                        Recommended Broker
                                    </h5>
                                    <hr style={{ color: "gray", margin: "0" }} />
                                    <li className="cursor-pointer">
                                        <img class="h-6 w-6 pb-1" alt="amreica" width={"25px"} height={"25px"} src={review1} />
                                        Interactive Brokers review
                                    </li>
                                    <li className="cursor-pointer">
                                        <img width={"25px"} height={"25px"} class="h-6 w-6 pb-1" alt="amreica" src={review2} /> Alpaca Trading review
                                    </li>
                                    <li className="cursor-pointer">
                                        <img width={"25px"} height={"25px"} class="h-6 w-6 pb-1" alt="amreica" src={review3} /> India
                                    </li>
                                    <li className="cursor-pointer">
                                        <img width={"25px"} height={"25px"} class="h-6 w-6 pb-1" alt="amreica" src={review4} /> Admirals (Admiral Markets) review
                                    </li>
                                    <li className="cursor-pointer">
                                        <img width={"25px"} height={"25px"} class="h-6 w-6 pb-1" alt="amreica" src={review5} /> IG review
                                    </li>
                                    <li className="cursor-pointer">
                                        <img width={"25px"} height={"25px"} class="h-6 w-6 pb-1" alt="amreica" src={review6} /> IC Markets review
                                    </li>
                                    <li className="cursor-pointer">
                                        <img width={"25px"} height={"25px"} class="h-6 w-6 pb-1" alt="amreica" src={review7} /> Pepperstone review
                                    </li>
                                    <li className="cursor-pointer">
                                        <img width={"25px"} height={"25px"} class="h-6 w-6 pb-1" alt="amreica" src={review8} /> MEXEM review
                                    </li>
                                    <li className="cursor-pointer">
                                        <img width={"25px"} height={"25px"} class="h-6 w-6 pb-1" alt="amreica" src={review9} /> XM review
                                    </li>
                                    <li className="cursor-pointer">
                                        <img width={"25px"} height={"25px"} class="h-6 w-6 pb-1" alt="amreica" src={review10} /> Fusion Markets review
                                    </li>
                                    <li className="cursor-pointer d-flex justify-content-center">
                                        <button className="btn btn-primary rounded-2 w-100 px-4 ">
                                            <Link to="/allreviews">
                                                All review
                                            </Link>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </li>

                    <li>

                        <span className="toggle noatag-need" onClick={ToolsOpen}>Tools   <img src={arrow} alt="img" style={{width:"20px", float:"right",marginRight:"20px"}} /></span>
                        {MenuOpen && Tools && <div
                            className={`services_menu inner_menu sm ${Tools ? 'open' : ''}`}
                        //   style={{ left: servicesOpen ? '0px' : '-100%',  transition: servicesOpen ? 'left 0.5s steps(10, end)' : 'none'

                        //             }}
                        >
                            <span className="back_menu" onClick={() => {
                                setTools(false)
                            }}>
                                {" "}
                                <BiArrowBack style={{ color: "#fff" }} />
                                <span className='text-white ms-2 mt-4'>Back</span>
                            </span>
                            <ul
                                className="pl-0"
                                style={{
                                    paddingLeft: "0px",
                                }}
                            >
                                <li className="cursor-pointer" >

                                    <div className="d-flex gap-2">
                                        <img
                                            class="h-6 w-6 pb-1"
                                            alt="amreica"
                                            width={"50px"}
                                            height={"50px"}
                                            style={{
                                                background: "#0050DB",
                                                borderRadius: "10px",
                                                padding: "5px 15px"
                                            }}
                                            src={searchicon1}
                                        />
                                        <div className="d-bloc">
                                            <Link>
                                                <h5 className="smallh" style={{ color: "white" }}> Find My Broker</h5>
                                            </Link>
                                            <p className="smallp" style={{ color: "white" }} >
                                                {" "}

                                            </p>
                                        </div>

                                    </div>


                                </li>
                                <li className="cursor-pointer" >
                                    <div className="d-flex gap-2">
                                        {" "}
                                        <img
                                            width={"50px"}
                                            height={"50px"}
                                            class="h-6 w-6 pb-1"
                                            alt="amreica"
                                            src={searchicon2}
                                        />{" "}
                                        <div className="d-bloc">
                                            <Link to={"/comparebroker"}>
                                                <h5 className="smallh" style={{ color: "white" }}> Compare Broker</h5>
                                            </Link>
                                            <p className="smallp" style={{ color: "white" }}>
                                                {" "}

                                            </p>
                                        </div>
                                    </div>
                                </li>

                                <li className="cursor-pointer" >
                                    <div className="d-flex gap-2">
                                        {" "}
                                        <img
                                            width={"50px"}
                                            height={"50px"}
                                            class="h-6 w-6 pb-1"
                                            alt="amreica"
                                            src={searchicon4}
                                        />{" "}
                                        <div className="d-block">
                                            <Link to={"/feeCalculator"}>
                                                <h5 className="smallh" style={{ color: "white" }}>     FX Fee Calculator</h5>
                                            </Link>
                                            <p className="smallp" style={{ color: "white" }}>
                                                {" "}

                                            </p>
                                        </div>
                                    </div>
                                </li>


                                <li className="cursor-pointer" >
                                    <div className="d-flex gap-2">
                                        <img
                                            width={"50px"}
                                            height={"50px"}
                                            class="h-6 w-6 pb-1"
                                            alt="amreica"
                                            src={searchicon7}
                                        />{" "}
                                        <div className="d-block">
                                            <Link>
                                                <h5 className="smallh" style={{ color: "white" }}>           Scam Broker Shield
                                                </h5>
                                            </Link>
                                            <p className="smallp" style={{ color: "white" }}> </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </div>}

                    </li>
                    <li>

                        <span className="toggle noatag-need" onClick={aboutOpen}>For Begginer   <img src={arrow} alt="img" style={{width:"20px", float:"right",marginRight:"20px"}} /></span>
                        {MenuOpen && Aboutus && <div
                            className={`services_menu inner_menu sm ${Aboutus ? 'open' : ''}`}
                        //   style={{ left: servicesOpen ? '0px' : '-100%',  transition: servicesOpen ? 'left 0.5s steps(10, end)' : 'none'

                        //             }}
                        >
                            <span className="back_menu" onClick={() => {
                                setAboutus(false)
                            }}>
                                {" "}
                                <BiArrowBack style={{ color: "#fff" }} />
                                <span className='text-white ms-2 mt-4'>Back</span>
                            </span>
                            <ul className="">

                                <hr style={{ color: "gray", margin: "0" }} />

                                <li className="text-slate-800 cursor-pointer">Best Online Broker</li>
                                <li className="text-slate-800 cursor-pointer">Scam broker check</li>

                                <li className="text-slate-800 cursor-pointer">Forex trading </li>
                                <li className="text-slate-800 cursor-pointer">CFD trading </li>
                            </ul>

                        </div>}

                    </li>
                    <li>

                        <span className="toggle noatag-need" onClick={beginneropen}>About us   <img src={arrow} alt="img" style={{width:"20px", float:"right",marginRight:"20px"}} /></span>
                        {MenuOpen && for_begginer && <div
                            className={`services_menu inner_menu sm ${for_begginer ? 'open' : ''}`}
                        //   style={{ left: servicesOpen ? '0px' : '-100%',  transition: servicesOpen ? 'left 0.5s steps(10, end)' : 'none'

                        //             }}
                        >
                            <span className="back_menu" onClick={() => {
                                setfor_begginer(false)
                            }}>
                                {" "}
                                <BiArrowBack style={{ color: "#fff" }} />
                                <span className='text-white ms-2 mt-4'>Back</span>
                            </span>
                            <ul className="">
                                <li className="text-slate-800 cursor-pointer">Our Team</li>

                                <li className="text-slate-800 cursor-pointer">For partner </li>
                                <li className="text-slate-800 cursor-pointer">Our methodology </li>
                            </ul>

                        </div>}

                    </li>



                </ul>
            </nav>

            {/* <div className="header_nav menu">
        <ul className="MyMenu">
        <li>
            <a href="/offers">Offers</a >
        </li>
        <li>
            <span className="toggle noatag-need">Company</span>
            <div
            className="company_menu inner_menu sm"
            style={{ left: "-100%" }}
            >
            <span className="back_menu">
                <img
                src="https://www.assignmentdesk.co.uk/templates/assignmentdesk/images/menu-left-arrow.svg"
                alt="left arrow icon"
                />{" "}
                Company
            </span>
            <ul className="drop-down clearfix">
                <li>
                <a href="/about-us">About Us</a >
                </li>
                <li>
                <a href="/why-choose-us">Why Choose Us</a >
                </li>
                <li>
                <a href="/vision-and-mission">Vision and Mission</a >
                </li>
                <li>
                <a href="/guarantees">Guarantees</a >
                </li>
                <li>
                <a href="/writers">Hire Writers</a >
                </li>
                <li>
                <a href="/reviews">Reviews</a >
                </li>
                <li>
                <a href="/contact-us">Contact Us</a >
                </li>
                <li>
                <a href="/how-it-works">How it Works</a >
                </li>
            </ul>
            </div>
        </li>
        <li>
            <a href="/blog">Blog</a >
        </li>{" "}
        </ul>
        </div> */}
        </div>
    )
}

export default ResponsiveNavbar